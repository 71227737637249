import useDevice from 'hooks/DesktopDevice.hook';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { BoxOverflow } from 'styles';
import Stack from 'styles/stack/Stack';
import palette from 'styles/theme/Palette';
import Typography from 'styles/typography/Typograpy';
import { IBenefit } from 'types/Member';
import { getShortDate } from 'utils/utils';
import MemberValueBenefit from './MemberValueBenefit.component';

const headerPlansList = [
	{ title: 'planBenefit', width: 160 },
	{ title: 'memberBenefit', width: 160 },
	{ title: 'eligible', width: 130 },
	{ title: 'eligibleAsOfDate', width: 160 },
	{ title: 'frequency', width: 200 },
	{ title: 'visusChange', width: 450 },
];

const MemberBenefits: FC = () => {
	const { t } = useTranslation();
	const { isDesktop } = useDevice();
	const { member } = useSelector((store: RootState) => store.member);

	const exam = member?.benefit.exam;
	const materials = member?.benefit.materials;
	const examAvailable = exam?.filter((e: IBenefit) => e.typology !== "NC")
	const materialAvailable = materials?.filter((e: IBenefit) => e.typology !== "NC")

	return (
		<BoxOverflow sx={{ paddingBottom: '5px', paddingRight: '5px' }} variant="xy">
			<Stack display={'inline-block'} minWidth={'100%'}>

				{examAvailable && materialAvailable && (examAvailable.length > 0 || materialAvailable.length > 0) &&
					<Stack
						direction={'row'}
						height={isDesktop ? 42 : 40}
						alignItems={'center'}
						justifyItems={'start'}
						sx={{ px: '20px', py: '10px' }}
					>
						{headerPlansList.map((item: { title: string; width: number | string }) => (
							<Typography
								key={item.title}
								variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
								width={item.width}
								minWidth={item.width}
								sx={{ pr: '1rem' }}
							>
								{t(`memberDetail.labels.${item.title}`)}
							</Typography>
						))}
					</Stack>
				}

				{examAvailable && examAvailable?.length > 0 &&
					<Stack
						direction={'row'}
						height={isDesktop ? 42 : 36}
						alignItems={'center'}
						sx={{
							px: '20px',
							py: '8px',
							background: palette.grey5.main,
							borderTopLeftRadius: '4px',
							borderTopRightRadius: '4px',
						}}
					>
						<Typography
							variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
							width={170}
						>
							{t(`memberDetail.labels.exam`)}
						</Typography>
					</Stack>
				}
				{examAvailable && examAvailable.map((exam: IBenefit, index: number) => (

					<Stack
						key={`member_row_exam_${index}`}
						direction={'row'}
						minHeight={56}
						alignItems={'center'}
						mt={'2px'}
						sx={{
							px: '20px',
							py: '16px',
							background: 'white',
							borderBottomLeftRadius:
								member?.benefit.exam.length === index + 1 ? '4px' : 0,
							borderBottomRightRadius:
								member?.benefit.exam.length === index + 1 ? '4px' : 0,
						}}
					>
						<Typography variant="body" minWidth={160}>
							{exam.planBenefit ? exam.planBenefit : exam.serviceName}
						</Typography>
						<MemberValueBenefit benefit={exam} />
						<Typography variant="body" minWidth={130}>
							{exam.eligible > 0 ? t('commons.yes') : t('commons.no')}
						</Typography>
						<Typography variant="body" minWidth={160}>
							{getShortDate(exam.eligibleAsOfDate)}
						</Typography>
						<Typography variant="body" minWidth={200}>
							{exam.frequency}
						</Typography>
						<Typography variant="body" minWidth={450}>
							{exam.visusChange}
						</Typography>
					</Stack>
				))}

				{materialAvailable && materialAvailable.length > 0 &&
					<Stack
						direction={'row'}
						height={isDesktop ? 42 : 36}
						alignItems={'center'}
						mt={1.25}
						sx={{
							px: '20px',
							py: '8px',
							background: palette.grey5.main,
							borderTopLeftRadius: '4px',
							borderTopRightRadius: '4px',
						}}
					>
						<Typography
							variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
							width={170}
						>
							{t(`memberDetail.labels.materials`)}
						</Typography>
					</Stack>
				}
				{materialAvailable && materialAvailable.map((material: IBenefit, index: number) => (
					<Stack
						key={`member_benefit_row_${index}`}
						direction={'row'}
						minHeight={56}
						alignItems={'center'}
						mt={'2px'}
						sx={{
							px: '20px',
							py: '16px',
							background: 'white',
							borderBottomLeftRadius:
								member?.benefit.materials.length === index + 1 ? '4px' : 0,
							borderBottomRightRadius:
								member?.benefit.materials.length === index + 1 ? '4px' : 0,
						}}
					>
						<Typography variant="body" minWidth={160}>
							{material.planBenefit ? material.planBenefit : material.serviceName}
						</Typography>
						<MemberValueBenefit benefit={material} />
						<Typography variant="body" minWidth={130}>
							{material.eligible > 0 ? t('commons.yes') : t('commons.no')}
						</Typography>
						<Typography variant="body" minWidth={160}>
							{getShortDate(material.eligibleAsOfDate)}
						</Typography>
						<Typography variant="body" minWidth={200}>
							{material.frequency}
						</Typography>
						<Typography variant="body" minWidth={450}>
							{material.visusChange}
						</Typography>
					</Stack>
				))}
			</Stack>
		</BoxOverflow>
	);
};

export default MemberBenefits;
