import { formatDayjsForBE } from 'utils/utils';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const searchMemberThunk = async (
	props: { values: any },
	thunkAPI: any,
) => {
	const getSearchMemberURL = `/member/search`;
	const newProps = {
		...props.values,
		date_of_service: formatDayjsForBE(props.values.date_of_service),
	};

	try {
		const response = await authFetch.post(getSearchMemberURL, newProps);
		return {
			...response.data,
			inputProps: newProps,
		};
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
