export interface IState {
	errorCode: number;
	errorMessage: string;
	paymentRun: IPaymentRunState;
	paymentDetails: IPaymentDetails;
}

export const PAGE_LIMIT = 8;

export enum PaymentDetailsType {
	EOP = 'EOP',
	GROUP_INVOICE = 'GROUP_INVOICE',
	ASSESSMENT_FEE = 'ASSESSMENT_FEE',
}

export enum PaymentPathnames {
	VIEW_PAYMENT_HISTORY = 'view-payment-history',
	EXPLANATION_OF_PAYMENT = 'explanation-of-payment',
	GROUP_INVOICE_ASSESSMENT = 'group-invoice-assessment',
	ASSESSMENT_FEE_INVOICE = 'assessment-fee-invoice',
}

export interface IPaymentClaimShort {
	claimId: number;
	externalClaimId: string;
	invoiceNumber: string;
	totalAmountPaid: number;
	assessmentFee: number;
}

interface IPaymentClaim {
	claimId: number;
	externalClaimId: string;
	dateOfService: string;
	location: string;
	totalCharges: number;
	contractualWriteOff: number;
	memberResponsible: number;
	claimPayment: number;
	assessmentFee: number;
	assessmentFeeTax: number;
	other: number;
	netDue: number;
}

export interface IPaymentRunState {
	claims: IPaymentClaimShort[];
	pages: number;
	page: number;
	paymentRunDate: string;
	paymentRunId: string;
	totalAmount: number;
}

interface IPaymentDetails {
	detailType: string;
	from: {
		name: string;
		address: string;
		fiscalCode: string;
		tin: string;
	};
	to: {
		name: string;
		address: string;
		fiscalCode: string;
		tin: string;
	};
	paymentInfo: {
		invoiceDate: string;
		invoiceNumber: string;
		typeOfService: string;
		paymentTerms: string;
		service: string;
		quantity: 0;
	};
	paymentAmount: {
		paymentId: string;
		unitPrice: number;
		amount: number;
		subTotal: number;
		vat: number;
		total: number;
	};
	paymentClaims: IPaymentClaim[];
	total: {
		claimId: number;
		dateOfService: string;
		location: string;
		totalCharges: number;
		contractualWriteOff: number;
		memberResponsible: number;
		claimPayment: number;
		assessmentFee: number;
		assessmentFeeTax: number;
		other: number;
		netDue: number;
	};
}
export interface IPaymentRunThunkProps {
	id: string;
}
export interface IPaymentDetailsThunkProps {
	paymentRunId: string;
	detailType: string;
}

export interface IPaymentFeeThunkProps {
	claimId: number;
}
