import { Stack as StackMui, StackProps as StackPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type StackProps = StackPropsMui

const StyledStack = styled(StackMui) <StackProps>`
    &::-webkit-scrollbar {
		height: 8px;
		width: 8px;
		border-radius: 6px;
		border: none;
	}
	&::-webkit-scrollbar-track {
		background-color: white;
		border-radius: 6px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 6px;
		height: 8px;
		width: 8px;
		background-color: #031434;
	}`

const Stack: React.FC<StackProps> = props => {
    return <StyledStack {...props}>
        {props.children}
    </StyledStack>
}

export default Stack;