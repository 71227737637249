import { Link as LinkMui, LinkProps } from '@mui/material';

import styled from 'styled-components';

const StyledUnstiledLink = styled(LinkMui)(({ theme }) => ({
	textDecoration: 'underline',
	textUnderlineOffset: '5px',
	cursor: 'pointer',
}));

const UnstiledLink: React.FC<LinkProps> = props => {
	return <StyledUnstiledLink {...props}>{props.children}</StyledUnstiledLink>;
};

export default UnstiledLink;
