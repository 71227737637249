import { useTranslation } from 'react-i18next';
import Typography from 'styles/typography/Typograpy';
import { IBenefit } from 'types/Member';
import { formatPerc, formatPrice } from 'utils/utils';

const MemberValueBenefit = (props: { benefit: IBenefit }) => {

  const { t } = useTranslation();
  const { coPay, coInsuranceAmount, typology, maxAmount } = props.benefit;

  return (
    <Typography variant="body" minWidth={160} maxWidth={160}>
      {typology === 'FC'
        ? t('memberDetail.labels.fullyCovered')
        : typology === 'D'
          ? formatPrice(maxAmount)
          : typology === 'C'
            ? `${t('memberDetail.labels.upTo')} ${formatPrice(maxAmount)}`
            : t('memberDetail.labels.notCovered')}
      {`${coPay > 0 || coInsuranceAmount !== 1
        ? ` ${t('memberDetail.labels.with')}`
        : ''
        }`}
      {coPay > 0 && <br />}
      {`${coPay > 0
        ? `${t('memberDetail.labels.coPayOf')} ${formatPrice(coPay)} ${coInsuranceAmount !== 1 ? t('memberDetail.labels.and') : ''
        }`
        : ''
        }`}
      {coInsuranceAmount !== 1 && <br />}
      {`${coInsuranceAmount !== 1
        ? `${t('memberDetail.labels.coInsOf')} ${formatPerc(
          coInsuranceAmount,
        )}% `
        : ''
        }`}
    </Typography>
  )
}

export default MemberValueBenefit
