import styled from 'styled-components';
import { Box, BoxOverflow } from 'styles';
import TableBody from './ClaimsTable/TableBody.component';
import TableHeader from './ClaimsTable/TableHeader.component';

export const Box130 = styled(Box)`
	width: 100%;
	max-width: 130px;
	min-width: 130px;
`;

export const Box100 = styled(Box)`
	width: 100%;
	max-width: 100px;
	min-width: 100px;
	text-align: center;
`;

const ClaimsTable = () => {
	return (
		<BoxOverflow variant="x">
			<TableHeader />
			<TableBody />
		</BoxOverflow>
	);
};

export default ClaimsTable;
