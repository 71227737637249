import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';
import 'dayjs/locale/it';

import {
	CheckSubmitClaims,
	ContactUsPage,
	Dashboard,
	LocationInformationPage,
	NewClaim,
	ProviderManual,
	ViewClaimDetails,
	ViewClaims,
	ViewPaymentDetails,
	ViewPaymentHistory,
} from '../pages';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
	useCheckAlive,
	useCookies,
	useGetProfile,
	useGetRoles,
	useLogin,
} from 'hooks/login.hook';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AuthenticatedTemplate } from '@azure/msal-react';
import CheckMemberDetail from 'pages/CheckMemberDetail';
import DashboardLayout from 'components/Layout/DashboardLayout.component';
import DialogConfirm from 'components/commons/Dialogs/DialogConfirm';
import DialogLocation from 'components/commons/Dialogs/location/DialogLocation';
import { LANGUAGES } from 'types/Localization';
import { LocalizationProvider } from '@mui/x-date-pickers';
import NotFound from 'pages/NotFound.page';
import { ProtectedRoute } from './ProtectedRoute';
import { RootState } from 'store/store';
import SharedLayout from 'components/Layout//SharedLayout/SharedLayout.component';
import UploadFileDialog from 'components/commons/Dialogs/UploadFileDialog';
import ViewPayment from 'pages/ViewPayment';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
	className?: string;
}

export const Router: React.FC<Props> = () => {
	const { i18n } = useTranslation();
	const [dateJsStarted, setDatejsStarted] = useState(false);
	const { profile } = useSelector((store: RootState) => store.user);

	//login
	useLogin();

	// check connection
	useCheckAlive();

	//get error messages
	useGetRoles();

	//cookies
	useCookies();

	// if the user is logged then lets load his informations
	useGetProfile();

	useEffect(() => {
		dayjs.locale(i18n.language);
		setDatejsStarted(true);
	}, [i18n.language]);

	const currentLanguage = LANGUAGES.find(
		el => el.id.toLowerCase() === i18n.language.toLowerCase(),
	);

	return !dateJsStarted ? null : (
		<AuthenticatedTemplate>
			<LocalizationProvider
				dateAdapter={AdapterDayjs}
				adapterLocale={currentLanguage ? currentLanguage.localeDayjs : 'en'}
			>
				<DialogLocation />
				<DialogConfirm />
				<UploadFileDialog />
				{profile && (
					<Routes>
						<Route path="/not-found" element={<NotFound />} />
						<Route path="/" element={<DashboardLayout />}>
							<Route path="" element={<Dashboard />} />
						</Route>
						<Route path="/" element={<SharedLayout />}>
							<Route
								path="check-and-submit"
								element={
									<ProtectedRoute
										component={<CheckSubmitClaims />}
										permissionsRequired={['check-eligibility']}
									/>
								}
							/>
							<Route
								path="check-and-submit/:id"
								element={
									<ProtectedRoute
										component={<CheckMemberDetail />}
										permissionsRequired={['check-eligibility']}
									/>
								}
							/>
							<Route
								path="check-and-submit/:id/new-claim"
								element={
									<ProtectedRoute
										component={<NewClaim />}
										permissionsRequired={['submit-claims']}
									/>
								}
							/>
							<Route
								path="view-claims"
								element={
									<ProtectedRoute
										component={<ViewClaims />}
										permissionsRequired={['view-claims-by-stores']}
									/>
								}
							/>
							<Route
								path="view-claims/:id"
								element={
									<ProtectedRoute
										component={<ViewClaimDetails />}
										permissionsRequired={['view-claims-by-stores']}
									/>
								}
							/>
							<Route
								path="location-information"
								element={
									<ProtectedRoute
										component={<LocationInformationPage />}
										permissionsRequired={['location-information-management']}
									/>
								}
							/>
							<Route
								path="view-payment-history"
								element={
									<ProtectedRoute
										component={<ViewPaymentHistory />}
										permissionsRequired={[
											'view-payments-and-invoices',
											'view-all-payments-for-provider-master-and-invoices',
										]}
									/>
								}
							/>
							<Route
								path="view-payment-history/:id"
								element={
									<ProtectedRoute
										component={<ViewPayment />}
										permissionsRequired={[
											'view-payments-and-invoices',
											'view-all-payments-for-provider-master-and-invoices',
										]}
									/>
								}
							/>
							<Route
								path="view-payment-history/:id/explanation-of-payment"
								element={
									<ProtectedRoute
										component={<ViewPaymentDetails />}
										permissionsRequired={[
											'view-payments-and-invoices',
											'view-all-payments-for-provider-master-and-invoices',
										]}
									/>
								}
							/>
							<Route
								path="view-payment-history/:id/group-invoice-assessment"
								element={
									<ProtectedRoute
										component={<ViewPaymentDetails />}
										permissionsRequired={[
											'view-payments-and-invoices',
											'view-all-payments-for-provider-master-and-invoices',
										]}
									/>
								}
							/>
							<Route
								path="view-payment-history/:id/assessment-fee-invoice/:claimId"
								element={
									<ProtectedRoute
										component={<ViewPaymentDetails />}
										permissionsRequired={[
											'view-payments-and-invoices',
											'view-all-payments-for-provider-master-and-invoices',
										]}
									/>
								}
							/>
							<Route
								path="documentation"
								element={
									<ProtectedRoute
										component={<ProviderManual />}
										permissionsRequired={['provider-manual']}
									/>
								}
							/>
							<Route
								path="contact-us"
								element={
									<ProtectedRoute
										component={<ContactUsPage />}
										permissionsRequired={['contact-us']}
									/>
								}
							/>
						</Route>

						<Route path="*" element={<Navigate to="/not-found" />} />
					</Routes>
				)}
			</LocalizationProvider>
		</AuthenticatedTemplate>
	);
};
