import { AppDispatch } from 'store/store';
import { downloadFile } from 'feature/utils/downloaderSlice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useFileOpener = () => {
	const dispatch = useDispatch<AppDispatch>();
	return useCallback(
		(fileUrl: string, fileName: string = 'download') => {
			dispatch(downloadFile({ url: fileUrl, fileName }));
		},
		[dispatch],
	);
};
