import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { MemberThunkProps } from 'types/Members';

export const getMemberDetailsThunk = async (
	props: MemberThunkProps & { callback: () => void },
	thunkAPI: any,
) => {
	let getMemberDetailsURL = `/member/portalMemberDetail`;

	const { subscriptionId, dateOfService } = props;
	const { profile } = thunkAPI.getState().user;

	if (subscriptionId && profile.providerId !== '' && dateOfService !== '') {
		getMemberDetailsURL =
			getMemberDetailsURL +
			`?subscriptionId=${subscriptionId}&providerId=${profile.providerId}&dateOfService=${dateOfService}`;

		try {
			const response = await authFetch.get(getMemberDetailsURL);
			props.callback();
			return { ...response.data, subscriptionId };
		} catch (error: any) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};
