import { Button, Stack, Typography } from 'styles';
import { ReactComponent as NotFoundIcon } from '../assett/icons/not_found.svg';
import useDevice from 'hooks/DesktopDevice.hook';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
	const { isTablet } = useDevice();
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Stack
			flexDirection={'row'}
			alignItems={'center'}
			justifyContent={'center'}
			height={'100vh'}
		>
			<Stack mr={isTablet ? '54px' : '204px'}>
				<Typography fontSize={isTablet ? '32px' : '50px'} mb={'15px'}>
					{t('not-found.sorry')}
				</Typography>
				<Typography fontSize={isTablet ? '13px' : '14px'} mb={'40px'}>
					{t('not-found.description')}
				</Typography>
				<Button
					variant="contained"
					sx={{ width: 'fit-content' }}
					onClick={() => navigate('/')}
				>
					{t('not-found.goBack')}
				</Button>
			</Stack>
			<NotFoundIcon width={isTablet ? 404 : 662} height={isTablet ? 326 : 534} />
		</Stack>
	);
};

export default NotFound;
