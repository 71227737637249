import { Box as BoxMui, BoxProps as BoxPropsMui } from '@mui/material';
import styled, { css } from 'styled-components';

import palette from 'styles/theme/Palette';

type BoxProps = BoxPropsMui & {
	$isMobile?: boolean;
};

const StyledBox = styled(BoxMui)<BoxProps>`
	z-index: 10;
	background-color: ${palette.white.main};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1.25rem;
	border-radius: 0.25rem;
	border: 0.063rem solid ${palette.primary.main};
	width: 14.375rem;
	height: 14rem;
	${({ $isMobile }) =>
		$isMobile
			? css`
					width: 12.5rem;
					height: 12.5rem;
			  `
			: css`
					width: 14.375rem;
					height: 14rem;
			  `};
`;

const UploadedFileBox: React.FC<BoxProps> = props => {
	return <StyledBox {...props}>{props.children}</StyledBox>;
};

export default UploadedFileBox;
