import ContactUs from 'components/ContactUs/ContactUs.component';
import { getLocationInfo } from 'feature/locationInformation/locationInformationSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';

const ContactUsPage = () => {
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(getLocationInfo({}));
	}, [dispatch]);

	return <ContactUs />;
};

export default ContactUsPage;
