import { Chip as ChipMui, ChipProps as ChipPropsMui } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import palette from 'styles/theme/Palette';
import { fonts } from 'styles/theme/Typography';

type ChipProps = ChipPropsMui & { $addResource?: boolean };

const StyledChip = styled(ChipMui)<ChipProps>`
	display: flex;
	justify-content: space-between;
	max-height: 32px;
	min-width: 108px;
	gap: 20px;
	background-color: ${palette.white.main};
	border: ${({ $addResource }) =>
		`1px solid ${$addResource ? palette.grey4.main : palette.primary2.main}`};
	color: ${palette.primary2.main};
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 0.2px;
	line-height: 18px;
	font-family: ${fonts.medium};
	padding: 0px 13px;
`;
const Chip: React.FC<ChipProps> = props => {
	return (
		<StyledChip {...props} sx={{ '.MuiChip-label': { padding: 0 } }}>
			{props.children}
		</StyledChip>
	);
};
export default Chip;
