import {
	TableHead as TableHeadMui,
	TableHeadProps as TableHeadPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type TableHeadProps = TableHeadPropsMui;

const StyledTableHead = styled(TableHeadMui)({});

const TableHead: React.FC<TableHeadProps> = props => {
	return <StyledTableHead {...props}>{props.children}</StyledTableHead>;
};

export default TableHead;
