import {
	Box,
	Chip,
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Stack,
	Typography,
} from 'styles';
import { ReactComponent as SearchIcon } from 'assett/icons/search_icon.svg';
import { ReactComponent as CloseSearchIcon } from 'assett/icons/clear_search_icon.svg';
import { ReactComponent as CloseChip } from 'assett/icons/close_chip_icon.svg';
import { ReactComponent as PlusIcon } from 'assett/icons/plus_icon.svg';

import { ConfigurationKey } from 'types/LocationInformation';
import { useDispatch, useSelector } from 'react-redux';
import {
	addCurrentConfiguration,
	createSelectSuggestFields,
	removeCurrentItem,
	resetSearchField,
	setSearchField,
} from 'feature/locationInformation/locationInformationSlice';
import { RootState } from 'store/store';
import palette from 'styles/theme/Palette';

interface EditConfigurationProps {
	configurationKey: ConfigurationKey;
	label: string;
	inputPlaceholder: string;
}

const EditConfiguration: React.FC<EditConfigurationProps> = ({
	inputPlaceholder,
	label,
	configurationKey,
}) => {
	const dispatch = useDispatch();
	const selectorSuggestFields = createSelectSuggestFields(configurationKey);
	const suggestFields = useSelector(selectorSuggestFields);
	const { currentConfiguration, searchField } = useSelector(
		(store: RootState) => store.locationInformation,
	);

	const resetField = (fieldKey: ConfigurationKey) => {
		dispatch(resetSearchField(fieldKey));
	};

	const handlerSearchField = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target;
		dispatch(
			setSearchField({
				text: value,
				fieldKey: name as ConfigurationKey,
			}),
		);
	};

	const addItem = (itemName: string, configurationKey: ConfigurationKey) => {
		dispatch(
			addCurrentConfiguration({
				itemName,
				configurationKey,
			}),
		);
		resetField(configurationKey);
	};

	const removeCurrentConfigItem = (itemToRemove: string) => {
		dispatch(
			removeCurrentItem({
				itemName: itemToRemove,
				configurationKey,
			}),
		);
	};

	return (
		<Stack gap="0.938rem">
			<Typography variant="bodyLink">{label}</Typography>
			<Box width="15.625rem">
				<FormControl>
					<OutlinedInput
						name={configurationKey}
						type="text"
						placeholder={inputPlaceholder}
						value={searchField[configurationKey]}
						onChange={handlerSearchField}
						sx={{ height: 56, outline: 'none', backgroundColor: palette.white.main }}
						endAdornment={
							<InputAdornment position="end">
								{!searchField[configurationKey] ? (
									<SearchIcon />
								) : (
									<IconButton onClick={() => resetField(configurationKey)}>
										<CloseSearchIcon />
									</IconButton>
								)}
							</InputAdornment>
						}
					/>
				</FormControl>
			</Box>
			<Stack
				direction="row"
				flexWrap="wrap"
				alignItems="flex-start"
				gap="0.313rem"
			>
				{suggestFields?.map(
					item =>
						item && (
							<Chip
								$addResource
								key={item}
								label={item}
								deleteIcon={<PlusIcon />}
								onDelete={() => addItem(item, configurationKey)}
							/>
						),
				)}
			</Stack>
			<Stack
				direction="row"
				flexWrap="wrap"
				alignItems="flex-start"
				gap="0.313rem"
			>
				{currentConfiguration[configurationKey]?.map(
					item =>
						item && (
							<Chip
								key={item}
								label={item}
								deleteIcon={<CloseChip />}
								onDelete={() => removeCurrentConfigItem(item)}
							/>
						),
				)}
			</Stack>
		</Stack>
	);
};

export default EditConfiguration;
