import { Grid as GridMui, GridProps as GridPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type GridProps = GridPropsMui

const StyledGrid = styled(GridMui)({})

const Grid:React.FC<GridProps> = props => {
    return <StyledGrid {...props}>
        {props.children}
    </StyledGrid>
}

export default Grid;