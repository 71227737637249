import {
	Link as LinkMui,
	LinkProps as LinkPropsMui,
	Typography,
} from '@mui/material';

import styled from 'styled-components';

type LinkProps = LinkPropsMui & { label?: string | null };

const StyledLink = styled(LinkMui)(({ theme }) => ({
	textTransform: 'uppercase',
	textDecoration: 'underline',
	textUnderlineOffset: '5px',
	color: theme.palette.primary1.main,
	cursor: 'pointer',
}));

const Link: React.FC<LinkProps> = props => {
	return (
		<StyledLink {...props}>
			{props.label ? (
				<Typography variant="action">{props.label}</Typography>
			) : (
				props.children
			)}
		</StyledLink>
	);
};

export default Link;
