import { createSlice } from '@reduxjs/toolkit';

interface IState {
	type: string;
	open: boolean;
	title: string;
	content: string;
	buttonCancel: boolean;
	buttonClose: boolean;
	labelButtonCancel: string;
	labelButtonSave: string;
}

const initialState: IState = {
	type: '',
	open: false,
	title: '',
	content: '',
	buttonClose: false,
	buttonCancel: false,
	labelButtonCancel: '',
	labelButtonSave: '',
};

const LocationHookSlice = createSlice({
	name: 'hooks-location',
	initialState,
	reducers: {
		setDialogLocationOpen: (state, { payload }) => {
			state.open = payload;
		},
		setInjectionState: (state, { payload }) => {
			state.title = payload.title;
			state.content = payload.content;
			state.labelButtonCancel = payload.labelButtonCancel;
			state.labelButtonSave = payload.labelButtonSave;
			state.buttonClose = payload.buttonClose;
			state.buttonCancel = payload.buttonCancel;
		},
		resetDialogLocation: () => initialState,
	},
});

export const { setDialogLocationOpen, setInjectionState, resetDialogLocation } =
	LocationHookSlice.actions;
export default LocationHookSlice.reducer;
