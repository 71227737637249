import {
	OutlinedInput as OutlinedInputMui,
	OutlinedInputProps as OutlinedInputPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type OutlinedInputProps = OutlinedInputPropsMui;

const StyledOutlinedInput = styled(OutlinedInputMui)({});

const OutlinedInput: React.FC<OutlinedInputProps> = props => {
	return <StyledOutlinedInput {...props}></StyledOutlinedInput>;
};

export default OutlinedInput;
