import * as React from 'react';

import {
	Drawer as DrawerMui,
	DrawerProps as DrawerPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type DrawerProps = DrawerPropsMui;

const StyledDrawer = styled(DrawerMui)({});

const Drawer: React.FC<DrawerProps> = props => {
	return <StyledDrawer {...props}>{props.children}</StyledDrawer>;
};

export default Drawer;
