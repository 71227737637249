import {
	LinearProgress as LinearProgressMui,
	LinearProgressProps as LinearProgressPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type LinearProgressProps = LinearProgressPropsMui;

const StyledLinearProgress = styled(LinearProgressMui)``;

const LinearProgress: React.FC<LinearProgressProps> = props => {
	return <StyledLinearProgress {...props} />;
};

export default LinearProgress;
