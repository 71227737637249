
import { thunkLoadingParams } from "feature/progressIndicator/progressIndicatorSlice";
import authFetch, { checkForUnauthorizedResponse } from "utils/axios";
import { getLocaleLanguage } from "utils/utils";
import { Reason } from "./contactUsSlice";


export const getAvailableReasonsThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: any,
) => {
    const lang = getLocaleLanguage();
    const PATH = `/cms/api/contact-us-base?locale=${lang}&populate=*`
    try {
		const response = await authFetch.get(PATH);
		return response.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const sendContactUsDataFormThunk = async (
	arg: thunkLoadingParams & { file: File | null, comment: string, selectedReasonValue: string, },
	thunkAPI: any,
) => {
	const { file, comment, selectedReasonValue, } = arg
	const { reasons } = thunkAPI.getState().contactUs; 
	const { profile  } = thunkAPI.getState().user;  
	const {  locationFields } = thunkAPI.getState().locationInformation;  
	const selectedReason:Reason = reasons.find((reason: Reason) => reason.value === selectedReasonValue)
	const PATH = '/cms/api/contact-us-forms';
	const formData = new FormData();
	const payload = {
		locationName:  profile.location ,
		contactTelephone: locationFields.phoneNumber,
		associateName:  profile.name ,
		email: locationFields.email,
		reason: selectedReason.key,
		comment
	}
	 formData.append('files.documentation', file || '');
	 formData.append('data', JSON.stringify(payload));
  try {
	  const response = await authFetch.post(PATH, formData, {  headers: {
        'Content-Type': 'multipart/form-data',
      }}); 
		return response.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}  
};
