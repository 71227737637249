import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { downloadFileThunk } from './downloaderThunk';

export const downloadFile = createAsyncThunk(
	'download/downloadFile',
	downloadFileThunk,
);

const download = createSlice({
	name: 'download',
	initialState: {},
	reducers: {},
	extraReducers: builder => {},
});

export default download.reducer;
