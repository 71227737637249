import { thunkLoadingParams } from "feature/progressIndicator/progressIndicatorSlice";
import { OpeningsHour } from "types/LocationInformation";
import authFetch, { checkForUnauthorizedResponse } from "utils/axios";

export const getAvailableConfigThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: any,
) => {

    const PATH = '/provider/getAvailableConfig'
    try {
		const response = await authFetch.get(PATH);
		return response.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getLocationInfoThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: any
) => {
    const { profile: { providerId } } = thunkAPI.getState().user
    const PATH = `/provider/getLocationInfo?providerLocationId=${providerId}`
    try {
		const response = await authFetch.get(PATH);
		return response.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
}; 

export const saveLocationInfoThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: any
) => {
	const { profile: { providerId } } = thunkAPI.getState().user
	const { locationFields: {
		address1, phoneNumber,websiteUrl
	}, openingsHour,  currentConfiguration: { languages, services, brands, products } } = thunkAPI.getState().locationInformation
	
	const openingsHourForBe = openingsHour.flatMap(((element: OpeningsHour) =>
		element.days.map((day) => ({
			weekDay: day + 1,
			openings: element.openings,
		}))
	));
	const PATH = `/provider/saveLocationInfo`
	const payload = {
		providerLocationId:providerId, 
		address1: address1.trim(), 
		phoneNumber:phoneNumber.trim(), websiteUrl,
		openingsHour: openingsHourForBe,
		currentLanguages:languages,
		currentServices:services,
		currentBrands:brands,
		currentProducts:products
	}
     try {
		const response = await authFetch.post(PATH,payload );
		return response.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	} 
}; 