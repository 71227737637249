import { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { dayjsForMaterial } from 'utils/utils';

type Props = {
	value: dayjs.Dayjs | null;
	onChange: (value: dayjs.Dayjs | null) => void;
	label: string;
	error?: boolean;
	errorText: string;
	maxDate?: dayjs.Dayjs | undefined;
	minDate?: dayjs.Dayjs | undefined;
};

const DatePickerField: FC<Props> = props => {
	const {
		value,
		onChange,
		label,
		error,
		errorText,
		maxDate: maxDateNF,
		minDate: mindateNF,
	} = props;

	const minDate = dayjsForMaterial(mindateNF);
	const maxDate = dayjsForMaterial(maxDateNF);

	const [invalidDate, setInvalidDate] = useState(false);

	return (
		<DesktopDatePicker
			value={value}
			views={[]}
			mask="__/__/____"
			label={label}
			onChange={e => {
				const newDate = dayjsForMaterial(e);
				const isValidDate =
					!newDate ||
					(newDate.isValid() &&
						(!maxDate || maxDate.diff(newDate) >= 0) &&
						(!minDate || newDate.diff(minDate) >= 0));
				onChange(isValidDate && newDate ? newDate : null);
				setInvalidDate(!isValidDate);
			}}
			minDate={minDate}
			maxDate={maxDate}
			renderInput={params => (
				<TextField
					{...params}
					error={!!error || invalidDate}
					variant="filled"
					helperText={error ? errorText : ''}
					autoComplete={'off'}
				/>
			)}
			disableOpenPicker
			InputProps={{
				disableUnderline: true,
			}}
		/>
	);
};

export default DatePickerField;
