import * as React from 'react';

import { styled } from '@mui/system';

interface BoxProps {
	variant: 'x' | 'y' | 'xy';
	children: React.ReactNode;
	sx?: any;
	mtScrollbar?: string;
	id?: string;
}

const StyledBox = styled('div', {
	shouldForwardProp: prop => prop !== 'sx',
	name: 'MuiBoxOverflow',
	slot: 'Root',
	overridesResolver: (props, styles) => [styles.root],
})<BoxProps & { mtscrollbar?: string }>(({ mtscrollbar }) => ({
	...(mtscrollbar && {
		[`&::-webkit-scrollbar-track`]: {
			marginTop: mtscrollbar,
		},
		[`&::-webkit-scrollbar-thumb`]: {
			marginTop: mtscrollbar,
		},
	}),
}));

const BoxOverflow: React.FC<BoxProps> = props => {
	const { mtScrollbar, children, id, ...other } = props;

	return (
		<StyledBox id={id} mtscrollbar={mtScrollbar} {...other}>
			{children}
		</StyledBox>
	);
};

export default BoxOverflow;
