import {
	ConfigurationKey,
	IState,
	LocationFIeld,
	OpeningDaily,
	OpeningsHour,
} from 'types/LocationInformation';
import {
	PayloadAction,
	createAsyncThunk,
	createSelector,
	createSlice,
} from '@reduxjs/toolkit';
import {
	getAvailableConfigThunk,
	getLocationInfoThunk,
	saveLocationInfoThunk,
} from './locationInformationThunk';

import { RootState } from 'store/store';
import { nanoid } from 'nanoid';

export const normalizeOpeningHourFe = (rawOpeningHour: any) => {
	const newOpeningHour: OpeningsHour[] = [];
	rawOpeningHour.forEach((elm: any) => {
		const matchingHours = newOpeningHour.find(
			newElm => JSON.stringify(newElm.openings) === JSON.stringify(elm.openings),
		);
		if (matchingHours) {
			matchingHours.days = [...matchingHours.days, elm.weekDay - 1];
		} else {
			newOpeningHour.push({
				key: nanoid(),
				days: [elm.weekDay - 1],
				openings: elm.openings,
			});
		}
	});
	newOpeningHour.forEach(elm =>
		elm.openings.forEach(
			(openingData: OpeningDaily) => (openingData.key = nanoid()),
		),
	);

	return newOpeningHour || [];
};

const numberOnlyRegex = /^[0-9\s]+$/;

const initialState: IState = {
	locationName: '',
	searchField: {
		brands: '',
		languages: '',
		products: '',
		services: '',
	},

	locationFields: {
		address1: '',
		phoneNumber: '',
		websiteUrl: '',
		email: '',
	},
	currentConfiguration: {
		languages: [],
		services: [],
		brands: [],
		products: [],
	},
	availableConfiguration: {
		languages: [],
		services: [],
		brands: [],
		products: [],
	},
	openingsHour: [
		{
			key: nanoid(),
			days: [],
			openings: [{ key: nanoid(), open: undefined, close: undefined }],
		},
	],

	errorCode: 0,
	errorMessage: '',
};

export const getAvailableConfig = createAsyncThunk(
	'localInformation/getAvailableConfig',
	getAvailableConfigThunk,
);
export const getLocationInfo = createAsyncThunk(
	'localInformation/getLocationInfo',
	getLocationInfoThunk,
);
export const saveLocationInfo = createAsyncThunk(
	'localInformation/saveLocationInfo',
	saveLocationInfoThunk,
);

const locationInformationSlice = createSlice({
	name: 'locationsInformation',
	initialState,
	reducers: {
		setLocationFields: (
			state,
			{ payload }: PayloadAction<{ text: string; fieldName: LocationFIeld }>,
		) => {
			const { fieldName, text } = payload;
			if (fieldName) {
				if (fieldName === 'phoneNumber') {
					if (numberOnlyRegex.test(text) || text === '') {
						state.locationFields[fieldName] = text;
					}
				} else {
					state.locationFields[fieldName] = text;
				}
			}
		},
		setSearchField: (
			state,
			{
				payload,
			}: PayloadAction<{
				text: string;
				fieldKey: ConfigurationKey;
			}>,
		) => {
			const { fieldKey, text } = payload;
			state.searchField[fieldKey] = text;
		},
		resetSearchField: (state, { payload }: PayloadAction<ConfigurationKey>) => {
			state.searchField[payload] = '';
		},
		resetState: state => {
			state.searchField = {
				brands: '',
				languages: '',
				products: '',
				services: '',
			};
		},
		removeCurrentItem: (
			state,
			{
				payload,
			}: PayloadAction<{
				itemName: string;
				configurationKey: ConfigurationKey;
			}>,
		) => {
			const { configurationKey, itemName } = payload;
			state.currentConfiguration[configurationKey] = state.currentConfiguration[
				configurationKey
			].filter(item => item !== itemName);
		},
		addCurrentConfiguration: (
			state,
			{
				payload,
			}: PayloadAction<{
				itemName: string;
				configurationKey: ConfigurationKey;
			}>,
		) => {
			const { configurationKey, itemName } = payload;
			state.currentConfiguration[configurationKey].push(itemName);
		},
		setHour: (
			state,
			{
				payload,
			}: PayloadAction<{
				hour: number;
				dataIndex: number;
				daysIndex: number;
				target: 'open' | 'close';
			}>,
		) => {
			const { hour, dataIndex, daysIndex, target } = payload;
			state.openingsHour[dataIndex].openings[daysIndex][target] = hour;
		},
		setDay: (
			state,
			{
				payload,
			}: PayloadAction<{
				days: number[];
				dataIndex: number;
			}>,
		) => {
			const { days, dataIndex } = payload;
			state.openingsHour[dataIndex].days = days.sort();
		},
		addOpeningConfigRow: state => {
			state.openingsHour.push({
				key: nanoid(),
				days: [],
				openings: [{ key: nanoid(), open: undefined, close: undefined }],
			});
		},
		addOpeningHourRow: (
			state,
			{
				payload,
			}: PayloadAction<{
				dataIndex: number;
			}>,
		) => {
			state.openingsHour[payload.dataIndex].openings.push({
				close: undefined,
				open: undefined,
				key: nanoid(),
			});
		},
		deleteOpeningConfigRow: (
			state,
			{
				payload,
			}: PayloadAction<{
				dataIndex: number;
				daysIndex: number;
			}>,
		) => {
			const { daysIndex, dataIndex } = payload;
			if (state.openingsHour.length > 1) {
				if (state.openingsHour[dataIndex].openings.length === 1) {
					state.openingsHour.splice(dataIndex, 1);
				} else {
					state.openingsHour[dataIndex].openings.splice(daysIndex, 1);
				}
			} else if (state.openingsHour[dataIndex].openings.length > 1) {
				state.openingsHour[dataIndex].openings.splice(daysIndex, 1);
			} else {
				state.openingsHour[dataIndex].days = [];
				state.openingsHour[dataIndex].openings[0].close = undefined;
				state.openingsHour[dataIndex].openings[0].open = undefined;
			}
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getAvailableConfig.fulfilled, (state, { payload }: any) => {
				const {
					facet_currentBrands,
					facet_currentLanguages,
					facet_currentServices,
				} = payload.aggregations;
				state.availableConfiguration.languages = facet_currentLanguages.buckets.map(
					(item: { key: string }) => item.key,
				);
				state.availableConfiguration.services = facet_currentServices.buckets.map(
					(item: { key: string }) => item.key,
				);
				state.availableConfiguration.brands = facet_currentBrands.buckets.map(
					(item: { key: string }) => item.key,
				);
			})
			.addCase(getLocationInfo.fulfilled, (state, { payload }: any) => {
				const {
					address1,
					currentBrands,
					currentLanguages,
					currentProducts,
					currentServices,
					openingsHour,
					phoneNumber,
					websiteUrl,
					email,
					providerLocationName,
				} = payload;
				if (openingsHour) {
					const newOpeningHour = normalizeOpeningHourFe(openingsHour);
					if (newOpeningHour) {
						state.openingsHour = newOpeningHour;
					}
				}
				state.locationFields = state.locationFields = {
					address1: address1.trim(),
					phoneNumber: phoneNumber.trim(),
					websiteUrl,
					email,
				};
				state.currentConfiguration = {
					languages: currentLanguages,
					products: currentProducts,
					services: currentServices,
					brands: currentBrands,
				};
				state.locationName = providerLocationName;
			})
			.addCase(saveLocationInfo.fulfilled, state => {
				state.searchField = {
					brands: '',
					languages: '',
					products: '',
					services: '',
				};
			});
	},
});

export const createSelectSuggestFields = (configurationKey: ConfigurationKey) =>
	createSelector(
		(state: RootState) => state.locationInformation,
		store => {
			const { searchField, availableConfiguration, currentConfiguration } = store;
			if (
				store.searchField[configurationKey] &&
				searchField[configurationKey].length > 1
			) {
				let filteredItems: string[] = [];
				filteredItems = availableConfiguration[configurationKey].filter(
					(item: string) =>
						item.toLowerCase().includes(searchField[configurationKey].toLowerCase()),
				);
				for (let i = 0; i < currentConfiguration[configurationKey].length; i++) {
					if (filteredItems.includes(currentConfiguration[configurationKey][i])) {
						filteredItems = filteredItems.filter(
							text =>
								text.toLowerCase() !==
								currentConfiguration[configurationKey][i].toLowerCase(),
						);
					}
				}
				if (
					!currentConfiguration[configurationKey]
						.map(el => el.toLowerCase())
						.includes(searchField[configurationKey].toLowerCase()) &&
					!availableConfiguration[configurationKey]
						.map(el => el.toLowerCase())
						.includes(searchField[configurationKey].toLowerCase())
				) {
					filteredItems.unshift(searchField[configurationKey]);
				}

				return filteredItems;
			} else {
				return [];
			}
		},
	);

export const {
	setLocationFields,
	removeCurrentItem,
	setSearchField,
	resetSearchField,
	addCurrentConfiguration,
	resetState,
	setHour,
	setDay,
	addOpeningConfigRow,
	addOpeningHourRow,
	deleteOpeningConfigRow,
} = locationInformationSlice.actions;
export default locationInformationSlice.reducer;
