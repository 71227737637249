import { Box, Stack, TextField, Typography } from 'styles';
import palette from 'styles/theme/Palette';
import { fonts } from 'styles/theme/Typography';
import { LocationFIeld } from 'types/LocationInformation';

interface FieldLocationProps {
	icon: React.ReactNode;
	text: string;
	isEditMode?: boolean;
	fieldName?: LocationFIeld;
	handlerTextField: (evt: React.ChangeEvent<HTMLInputElement>) => void;
	isDisabled?: boolean;
}

const FieldLocation: React.FC<FieldLocationProps> = ({
	icon,
	text,
	isEditMode,
	fieldName,
	handlerTextField,
	isDisabled,
}) => {
	return (
		<Stack
			border={`1px solid ${isEditMode ? palette.grey4.main : 'transparent'}`}
			borderRadius={1}
			direction="row"
			gap="0.938rem"
			alignItems="center"
			sx={{
				backgroundColor: isDisabled ? palette.grey6.main : palette.white.main,
			}}
			height="3.5rem"
			px={2}
		>
			<Box display="flex">{icon}</Box>
			{!isEditMode ? (
				<Typography flexGrow={0} variant="value2">
					{text}
				</Typography>
			) : (
				<TextField
					disabled={isDisabled}
					name={fieldName}
					fullWidth
					variant="standard"
					InputProps={{
						disableUnderline: true,
					}}
					onChange={handlerTextField}
					value={text}
					sx={{
						'.MuiInput-input': {
							height: '18px',
							fontFamily: fonts.medium,
							fontWeight: 600,
							letterSpacing: '0.2px',
							fontSize: '13px',
							lineHeight: '16px',
							padding: 0,
							flexGrow: 0,
							backgroundColor: isDisabled ? palette.grey6.main : palette.white.main,
						},
					}}
				/>
			)}
		</Stack>
	);
};

export default FieldLocation;
