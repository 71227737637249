import { Stack, UnstiledLink } from 'styles';

import BreadcrumbsMui from 'styles/breadcrumbs/Breadcrumbs';
import { ReactComponent as ChevronBreadCrumbIcon } from 'assett/icons/chevron_breadcrumb_icon.svg';
import { IClaim } from 'types/Claim';
import { RootState } from 'store/store';
import Typography from 'styles/typography/Typograpy';
import { getClaimStatusProperty } from 'utils/utils';
import palette from 'styles/theme/Palette';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ClaimStatusBreadcrumbs = ({ claim }: { claim: IClaim }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { features } = useSelector((store: RootState) => store.user);
	const featuresRole = features.map(el => el.code);

	const { claimStatusId } = claim;

	let pathnames: (1 | 3 | 4 | 9 | 5 | 10 | 11 | 12 | 13 | 14)[] = [1, 3, 4, 9, 5]; //this is the normal workflow: pending doc, submitted, approved, sent for payments, paid

	if (claim.claimStatusId === 13) {
		pathnames = [...pathnames, 13];
	}
	if (claim.claimStatusId === 14) {
		pathnames = [...pathnames, 13, 14];
	}

	if (claimStatusId && [2, 7, 8].includes(claimStatusId)) {
		//this is the rejected/void status. we show it withoud breadcrumbs
		return (
			<Typography
				variant="body"
				sx={{
					color: 'primary.main',
					textDecoration: 'none',
					fontWeight: '700',
					textUnderlineOffset: 5,
				}}
			>
				{t(`forms.status.${getClaimStatusProperty(claimStatusId)}`)}
			</Typography>
		);
	}

	return (
		<BreadcrumbsMui
			separator={<ChevronBreadCrumbIcon />}
			sx={{ marginBottom: 0, marginTop: 0 }}
		>
			{pathnames.map((elStatus, index) => {
				return (
					<Stack key={index}>
						{elStatus === 1 &&
							claimStatusId === 1 &&
							featuresRole.includes('submit-claims') ? (
							<UnstiledLink
								variant="body"
								onClick={() => {
									navigate(
										`/check-and-submit/${claim.memberFullName}/new-claim?claimId=${claim.claimId}`,
									);
								}}
							>
								<StyledBreadcrumbStep
									variant="body"
									$currentStep={claimStatusId === elStatus}
								>
									{t(`forms.status.${getClaimStatusProperty(elStatus)}`)}
								</StyledBreadcrumbStep>
							</UnstiledLink>
						) : (
							<StyledBreadcrumbStep
								variant="body"
								$currentStep={claimStatusId === elStatus}
							>
								{t(`forms.status.${getClaimStatusProperty(elStatus)}`)}
							</StyledBreadcrumbStep>
						)}
					</Stack>
				);
			})}
		</BreadcrumbsMui>
	);
};

const StyledBreadcrumbStep = styled(Typography) <
	typeof Typography & { $currentStep: boolean }
>`
	color: ${$currentStep =>
		({ $currentStep } ? palette.primary.main : palette.grey2.main)};
	font-weight: ${({ $currentStep }) => ($currentStep ? 700 : 'normal')};
`;

export default ClaimStatusBreadcrumbs;
