import {
	resetLocation,
	resetSearch,
	searchLocation,
	setSearchLocationValue,
} from 'feature/search/searchSlice';
import { useEffect, useState } from 'react';
import { IconButton, OutlinedInput } from 'styles';

import { useTheme } from '@mui/material';
import { ReactComponent as CloseSearchIcon } from 'assett/icons/clear_search_icon.svg';
import { ReactComponent as SearchIcon } from 'assett/icons/search_icon.svg';
import { useDebounce } from 'hooks/debouncer.hook';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FormControl from 'styles/form/FormControl';
import InputAdornment from '../../../../styles/input/InputAdornment';

const InputSearch: React.FC = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const [searchLocationTemp, setSearchLocationTemp] = useState('');
	const dispatch = useDispatch<any>();

	const handleOnChange = (e: any) => {
		const { value } = e.target;
		if (!value.length) {
			dispatch(resetLocation());
			dispatch(setSearchLocationValue(''));
		}
		setSearchLocationTemp(value);
	};

	const searchLocationValueDeb = useDebounce(searchLocationTemp);

	useEffect(() => {
		dispatch(setSearchLocationValue(searchLocationValueDeb));
		if (searchLocationValueDeb.length) {
			dispatch(searchLocation(''));
		}
	}, [searchLocationValueDeb, dispatch]);

	const handleClearInput = () => {
		setSearchLocationTemp('');
		dispatch(resetSearch());
	};

	return (
		<FormControl fullWidth>
			<OutlinedInput
				type="text"
				placeholder={t('commons.buttons.search') || ''}
				value={searchLocationTemp}
				onChange={handleOnChange}
				sx={{
					height: 56,
					outline: 'none',
					'& input': {
						fontWeight: 600,
						fontSize: 13,
						color: theme.palette.primary.main,
					},
					'& input::placeholder': {
						opacity: 1,
						fontWeight: 600,
						fontSize: 13,
						color: theme.palette.primary.main,
					},
				}}
				endAdornment={
					<InputAdornment position="end">
						{!searchLocationTemp ? (
							<SearchIcon />
						) : (
							<IconButton onClick={() => handleClearInput()}>
								<CloseSearchIcon />
							</IconButton>
						)}
					</InputAdornment>
				}
			/>
		</FormControl>
	);
};

export default InputSearch;
