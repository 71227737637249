import { Link , LinkProps } from "react-router-dom";
import styled from "styled-components";
import Typography from "../typography/Typograpy";
import * as React from "react";


type LinkRouterProps = LinkProps & {
    label:string;
}

const StyledLinkRouter = styled(Link)(({theme})=> ({
      textTransform:'uppercase',
      textDecoration:'underline',
      textUnderlineOffset:'5px',
      color: theme.palette.primary1.main,
      cursor:'pointer'
}));

const LinkRouter:React.FC<LinkRouterProps> = props => {
    return (
        <StyledLinkRouter {...props} to={props.to} >
            <Typography variant="action" >{props.label}</Typography>
        </StyledLinkRouter>
    )
}

export default LinkRouter