interface IDocumentData {
	id: number;
	attributes: {
		url: string;
		name: string;
		ext: string;
		size: number;
		mime: string;
	};
}

export const MIME_TYPES = {
	PDF: ['application/pdf'],
	IMAGE: ['mage/bmp', 'image/jpeg', 'image/x-png', 'image/png', 'image/gif'],
};

interface IData {
	data: IDocumentData[];
}

export interface IDocument {
	attachment: IData;
	content: string;
	createdAt: string;
	locale: string;
	localizations: {};
	publishedAt: string;
	reference: [];
	title: string;
	uid: string;
	updatedAt: string;
	typology: string | any;
}

export interface IDocumentStructure {
	id: number;
	attributes: IDocument;
}

export interface IState {
	errorCode: number;
	errorMessage: string;
	documents: IDocumentStructure[];
	expandView: boolean;
}
