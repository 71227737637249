import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ICookies } from 'types/Cookies';
import { getCookiesThunk } from './cookiesThunk';

const initialState: ICookies = {
	errorCode: 0,
	errorMessage: '',
};

export const getCookiesContent = createAsyncThunk(
	'cookies/getCookiesContent',
	getCookiesThunk,
);

const cookiesSlice = createSlice({
	name: 'cookies',
	initialState,
	reducers: {
		resetCookies: () => initialState,
	},
	extraReducers: builder => {
		builder.addCase(getCookiesContent.fulfilled, (state, { payload }) => {
			state.errorCode = 0;
			state.errorMessage = '';
		});
	},
});

export const { resetCookies } = cookiesSlice.actions;
export default cookiesSlice.reducer;
