import { SvgIcon as SvgIconMui, SvgIconProps as SvgIconPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type SvgIconProps = SvgIconPropsMui

const StyledSvgIcon = styled(SvgIconMui)<SvgIconProps>({})

const SvgIcon:React.FC<SvgIconProps> = props => {
    return <StyledSvgIcon {...props} inheritViewBox>
        {props.children}
    </StyledSvgIcon>
}

export default SvgIcon;