import { FC, useEffect } from 'react';

import { RootState } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'pages/Error.page';

export const ProtectedRoute: FC<{
	component: JSX.Element;
	permissionsRequired: string[];
}> = ({ component, permissionsRequired }) => {
	const navigate = useNavigate();

	const { features } = useSelector((store: RootState) => store.user);
	const featuresRole = features.map(el => el.code);

	useEffect(() => {
		if (
			featuresRole.length > 0 &&
			!permissionsRequired.find(permission => featuresRole.includes(permission))
		)
			navigate('/');
	}, [featuresRole, navigate, permissionsRequired]);

	return (
		<ErrorBoundary fallback={<ErrorPage navigate={navigate} />}>
			{component}
		</ErrorBoundary>
	);
};
