import {
	Breadcrumbs as BreadcrumbsMui,
	BreadcrumbsProps as BreadcrumbsPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type BreadcrumbsProps = BreadcrumbsPropsMui;

const StyledBreadcrumbs = styled(BreadcrumbsMui)<BreadcrumbsProps>``;

const Breadcrumbs: React.FC<BreadcrumbsProps> = props => {
	return <StyledBreadcrumbs {...props}>{props.children}</StyledBreadcrumbs>;
};

export default Breadcrumbs;
