import {  Grid2Props as Grid2PropsMui, } from "@mui/material/Unstable_Grid2";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import * as React from "react";
import styled from "styled-components";

type Grid2Props = Grid2PropsMui

const StyledGrid = styled(Grid)({})

const Grid2:React.FC<Grid2Props> = props => {
    return <StyledGrid {...props}>
        {props.children}
    </StyledGrid>
}

export default Grid2;