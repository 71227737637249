import { useGetLocales } from 'hooks/login.hook';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import merge from 'lodash/merge';
import { ReactNode, useEffect } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getLanguageStorage } from 'utils/localStorage';

import { DEFAULT_LANGUAGE, LANGUAGE_CODES } from 'types/Localization';
import { getLocaleLanguage } from './utils';

type Props = {
	children: ReactNode;
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: DEFAULT_LANGUAGE,
		debug: false,
		fallbackLng: LANGUAGE_CODES.EN,
		supportedLngs: [
			LANGUAGE_CODES.EN,
			LANGUAGE_CODES.ES,
			LANGUAGE_CODES.IT,
			LANGUAGE_CODES.DE,
		],
		interpolation: {
			escapeValue: false,
		},
		detection: { order: ['localStorage'] },
		load: 'languageOnly',
	});

const TranslationProvider = ({ children }: Props) => {
	const { localeTranslations } = useSelector(
		(store: RootState) => store.textsLocales,
	);

	//get error messages
	useGetLocales();

	useEffect(() => {
		if (!localeTranslations) {
			return;
		}

		const lan = getLocaleLanguage();
		const tmpLocale = {
			[lan]: {
				translation: localeTranslations,
			},
		};
		const updatedTranslations = merge(DEFAULT_LANGUAGE, tmpLocale);

		i18n.addResourceBundle(
			lan,
			'translation',
			updatedTranslations[lan]['translation'],
			false,
			true,
		);
		i18n.changeLanguage(getLanguageStorage());
	}, [localeTranslations]);

	return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default TranslationProvider;
