import { useMediaQuery, useTheme } from '@mui/material';

import { Box } from 'styles';
import CardMedia from 'styles/card/CardMedia';
import Container from 'styles/container/Container';
import { FooterDesktop } from './FooterDesktop/FooterDesktop';
import Logo from 'assett/icons/logo.svg';
import { Outlet } from 'react-router-dom';
import { RootState } from 'store/store';
import SelectLanguage from 'components/commons/form/SelectLanguage';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import UserProvider from 'components/Dahsboard/User/UserProvider.component';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setDialogOpen, setInjectionState } from 'feature/hooks/dialogSlice';
import useDialogConfirm, { VERSION } from 'hooks/DialogConfirm.hook';

const DashboardLayout = () => {
	const { t } = useTranslation();
	const { profile } = useSelector((store: RootState) => store.user);
	const theme = useTheme();
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const dispatch = useDispatch();
	const confirmDialog = useDialogConfirm();

	return (
		<Container>
			<Stack
				justifyContent="space-between"
				height={!desktop ? `${window.innerHeight}px` : '100vh'}
			>
				<Box>
					<Stack
						sx={{ mt: 6.25 }}
						direction={'row'}
						justifyContent={'space-between'}
					>
						<Stack>
							<Typography variant="title1">{t('dashboard.pageTitle')}</Typography>
							<Typography
								variant={!!desktop ? 'title2' : 'title4'}
								sx={{ color: 'primary3.main' }}
							>
								{profile?.name as string}
							</Typography>
						</Stack>

						<Stack alignItems={'flex-end'} sx={{ mt: { sm: 0, lg: 2.5 } }}>
							<Box width="48px" height="60px">
								<CardMedia
									component="img"
									image={Logo}
									onClick={() => {
										dispatch(setInjectionState(confirmDialog.open(VERSION)));
										dispatch(setDialogOpen(true));
									}}
								/>
							</Box>
						</Stack>
					</Stack>
					<Stack direction={'row'} justifyContent="space-between">
						<Box width={{ sm: '68%', lg: '60%' }}>
							<UserProvider />
						</Box>
						<Box>
							<SelectLanguage />
						</Box>
					</Stack>
				</Box>
				<Stack mb={!!desktop ? 6.5 : 2.5}>
					<Outlet />
				</Stack>
				<FooterDesktop />
			</Stack>
		</Container>
	);
};

export default DashboardLayout;
