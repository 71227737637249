import {
	Stepper as StepperMui,
	StepperProps as StepperPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type StepperProps = StepperPropsMui;

const StyledStepper = styled(StepperMui)``;

const Stepper: React.FC<StepperProps> = props => {
	return <StyledStepper {...props} />;
};

export default Stepper;
