import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { getLocaleLanguage } from 'utils/utils';

export const getDocumentsThunk = async (_: any, thunkAPI: any) => {
	const lang = getLocaleLanguage();

	const getDocumentsURL = `/cms/api/manuals?locale=${lang}&populate=*`;

	try {
		const response = await authFetch.get(getDocumentsURL);
		return response.data?.data || undefined;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
