import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { getLocaleLanguage } from 'utils/utils';

export const getFooterLinksThunk = async (_: any, thunkAPI: any) => {
	const lang = getLocaleLanguage();

	const getFooterLinksUrl = `/cms/api/provider-portal-footer/?populate=*&locale=${lang}`;

	try {
		const response = await authFetch.get(getFooterLinksUrl);
		return response.data.data.attributes.links;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
