import { Radio as RadioMui, RadioProps as RadioPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type RadioProps = RadioPropsMui

const StyledRadio = styled(RadioMui)({})

const Radio:React.FC<RadioProps> = props => {
    return <StyledRadio {...props} disableFocusRipple disableTouchRipple />
}

export default Radio;