import {
	StepButton as StepButtonMui,
	StepButtonProps as StepButtonPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type StepButtonProps = StepButtonPropsMui;

const StyledStepButton = styled(StepButtonMui)``;

const StepButton: React.FC<StepButtonProps> = props => {
	return <StyledStepButton {...props} />;
};

export default StepButton;
