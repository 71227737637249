import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { uploadDocument } from 'feature/upload/uploadSlice';

interface IState {
	open: boolean;
}

const initialState: IState = {
	open: false,
};

const uploadFileDialogSlice = createSlice({
	name: 'uploadFileDialog',
	initialState,
	reducers: {
		setDialogOpen: (state, isOpen: PayloadAction<boolean>) => {
			state.open = isOpen.payload;
		},
		resetUploadFileDialogState: state => {
			state.open = false;
		},
	},
	extraReducers: builder => {
		builder.addCase(uploadDocument.rejected, (state: any, action: AnyAction) => {
					if (action.meta.aborted) {
						return;
					}
			uploadFileDialogSlice.caseReducers.resetUploadFileDialogState(state);
		});
	},
});

export const { setDialogOpen, resetUploadFileDialogState } =
	uploadFileDialogSlice.actions;
export default uploadFileDialogSlice.reducer;
