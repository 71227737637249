import { SxProps, Theme, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import ListItem from 'styles/list/ListItem';
import ListItemButton from 'styles/list/ListItemButton';
import ListItemIcon from 'styles/list/ListItemIcon';
import ListItemText from 'styles/list/ListItemText';
import { Box, Tooltip } from 'styles';
import Typography from 'styles/typography/Typograpy';
import { theme } from 'styles/theme/ThemeStyle';
import { useLocation } from 'react-router-dom';
import mobile from 'is-mobile';

type ListButtonProps = {
	onClick: () => void;
	action: any;
	open: boolean;
	sx?: SxProps<Theme>;
};

const ListItemButtonCust: FC<ListButtonProps> = props => {
	const { open, onClick, action, sx = {} } = props;
	const location = useLocation();
	const [openTooltip, setOpenTooltip] = useState(false);
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = mobile({ tablet: true, featureDetect: true });

	const handlerClick = () => {
		if (openTooltip) {
			setOpenTooltip(false);
		}
		onClick();
	};

	const handleClose = () => {
		setOpenTooltip(false);
	};

	const handleOpen = () => {
		setOpenTooltip(true);
	};

	return (
		<ListItem disablePadding sx={{ display: 'block' }}>
			<ListItemButton
				onMouseEnter={handleOpen}
				onMouseLeave={handleClose}
				sx={{
					minHeight: 0,
					justifyContent: open ? 'initial' : 'center',
					p: 0,
					mr: open ? 1.5 : 'auto',
					...sx,
				}}
				onClick={handlerClick}
			>
				<Tooltip
					open={!open && !isMobile ? openTooltip : false}
					disableTouchListener
					title={action.name}
					placement="right"
				>
					<Box display="flex">
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : 'auto',
								justifyContent: 'center',
								px: !desktop ? 0.5 : 0,
							}}
						>
							{location.pathname.includes(action.link)
								? action.icon
								: action.unselected_route || action.icon}
						</ListItemIcon>
					</Box>
				</Tooltip>
				<ListItemText sx={{ opacity: open ? 1 : 0, ml: !!desktop ? -1 : -1.875 }}>
					<Typography
						variant="bodyLink"
						sx={{
							color: location.pathname.includes(action.link)
								? 'primary.main'
								: 'grey3.main',
						}}
					>
						{action.name}
					</Typography>
				</ListItemText>
			</ListItemButton>
		</ListItem>
	);
};

export default ListItemButtonCust;
