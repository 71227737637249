import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useTranslation } from 'react-i18next';

import { useFileOpener } from 'hooks/downloader.hook';
import { Button } from 'styles'
import useDialogConfirm, { GENERIC_ERROR } from 'hooks/DialogConfirm.hook';
import { ReactComponent as DownloadIcon } from 'assett/icons/download.svg';

const DownloadAttestation: FC = () => {
  const { t } = useTranslation();
  const fileOpener = useFileOpener();
  const dialogConfirm = useDialogConfirm();

  const { documents } = useSelector(
    (store: RootState) => store.documentation,
  );

  const attestation = documents.find((element) => element.attributes.typology === "attestation-form");
  const url = attestation?.attributes?.attachment?.data[0].attributes.url;
  const name = attestation?.attributes?.attachment?.data[0].attributes.name;
  const URL_RAW = `${process.env.REACT_APP_API_URL}/cms${url}`;

  const downloadDoc = () => {
    if (url) {
      fileOpener(URL_RAW, name);
      return;
    }
    dialogConfirm.open(GENERIC_ERROR);
  }

  return (
    <Button
      variant="underline"
      sx={{ marginBottom: '20px', marginTop: '10px', width: 'fit-content' }}
      onClick={downloadDoc}
    >
      <DownloadIcon width={24} height={24} />
      {t('newClaim.downloadAttestation')}
    </Button>
  )
}

export default DownloadAttestation
