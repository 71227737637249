import { useTheme } from '@mui/material';
import useDevice from 'hooks/DesktopDevice.hook';
import { useSortedClaim } from 'hooks/useSortedClaim';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store/store';
import { Box, Button, Stack, Typography } from 'styles';
import {
	IPaymentClaimShort,
	PAGE_LIMIT,
	PaymentPathnames,
} from 'types/Payment';
import { formatDateWithoutZulu, formatPrice, paginate } from 'utils/utils';

const TYPOGRAPHY_WIDTH = 37.5;

const PaymentClaimsList = () => {
	const theme = useTheme();
	const { isDesktop } = useDevice();
	const { paymentRun } = useSelector((store: RootState) => store.payment);

	const boxSX = {
		display: 'flex',
		alignItems: 'center',
		width: theme.spacing(TYPOGRAPHY_WIDTH),
		gap: theme.spacing(1.25),
	};

	const sortedList = useSortedClaim();

	const List = paginate(sortedList, PAGE_LIMIT, paymentRun.page).map(
		(paymentClaim: IPaymentClaimShort) => {
			return (
				<Stack
					key={paymentClaim.claimId}
					display={'flex'}
					flexDirection={'row'}
					justifyContent={'start'}
					alignItems={'center'}
					alignSelf={'stretch'}
					borderRadius={'4px'}
					bgcolor={theme.palette.white.main}
					mb={1}
					py={1}
					px={1.25}
					height={theme.spacing(6.5)}
					gap={!isDesktop ? theme.spacing(1.25) : 0}
				>
					<Box sx={boxSX}>
						<Typography variant="body">{paymentClaim.externalClaimId}</Typography>
					</Box>
					<Box sx={boxSX}>
						<Typography variant="body">{paymentClaim.invoiceNumber}</Typography>
					</Box>
					<Box sx={boxSX}>
						<Typography variant="body">
							{formatPrice(paymentClaim.totalAmountPaid)}
						</Typography>
					</Box>
					<Box sx={boxSX}>
						<Typography variant="body">
							{formatPrice(paymentClaim.assessmentFee)}
						</Typography>
					</Box>
					<Box sx={boxSX}>
						<Link
							style={{
								textDecoration: 'none',
							}}
							to={`/${PaymentPathnames.VIEW_PAYMENT_HISTORY}/${paymentRun.paymentRunId
								}|${formatDateWithoutZulu(paymentRun.paymentRunDate)}/${PaymentPathnames.ASSESSMENT_FEE_INVOICE
								}/${paymentClaim.claimId}`}
						>
							<Button variant="linkDark">{t('payment.labels.viewDetail')}</Button>
						</Link>
					</Box>
				</Stack>
			)

		}
	);

	const progressIndicator = useSelector(
		(state: RootState) => state.progressIndicator,
	);



	return (
		<>
			{List.length > 0 ? (
				List
			) : (
				<Typography variant="bodyLink" sx={{ padding: 1.2 }}>
					{progressIndicator?.isLoading ? '' : t('payment.labels.noClaimFound')}
				</Typography>
			)}
		</>
	);
};

export default PaymentClaimsList;
