import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ErrorInfoIcon } from 'assett/icons/error_info_icon.svg';
import { ReactComponent as InfoIcon } from 'assett/icons/info_icon.svg';
import { handleChange, resetForm } from 'feature/form/formSlice';
import { RootState } from 'store/store';
import { FormControl, Typography } from 'styles';
import DatePicker from 'styles/datePicker/DatePicker';
import Stack from 'styles/stack/Stack';

const DateOfService = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dateOfService } = useSelector((store: RootState) => store.form);
  const [errorDate, setErrorDate] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetForm());
    dispatch(
      handleChange<any>({
        name: 'dateOfService',
        value: dayjs(),
      }),
    );
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  return (
    <Stack sx={{ rowGap: 1 }}>
      <FormControl sx={{ width: { sm: 340, lg: 368 } }}>
        <DatePicker
          variant="margin"
          label={t('check-and-submit.dateOfService')}
          value={dateOfService}
          onChange={value => {
            dispatch(
              handleChange<any>({
                name: 'dateOfService',
                value: value,
              }),
            );
          }}
          minDate={dayjs().subtract(45, 'day')}
          maxDate={dayjs()}
          setErrorDate={isInvalidDate => setErrorDate(isInvalidDate)}
        />
      </FormControl>
      <Stack flexDirection={'row'} alignItems={'center'}>
        {errorDate ? (
          <ErrorInfoIcon style={{ marginRight: '8px' }} />
        ) : (
          <InfoIcon style={{ marginRight: '8px' }} />
        )}
        <Typography
          variant="dateInfo"
          sx={{ color: errorDate ? '#902727' : 'inherit' }}
        >{`${t('check-and-submit.errors.dateOfService')}`}</Typography>
      </Stack>
    </Stack>
  )
}

export default DateOfService
