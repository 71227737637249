import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	getAvailableReasonsThunk,
	sendContactUsDataFormThunk,
} from './contactUsThunk';

export interface Reason {
	id: string;
	key: string;
	value: string;
}

interface IState {
	reasons: Reason[];
	isOpenSubmitAppeal: boolean;
}

const initialState: IState = {
	isOpenSubmitAppeal: false,
	reasons: [],
};

export const getAvailableReasons = createAsyncThunk(
	'contactUs/getAvailableReasons',
	getAvailableReasonsThunk,
);

export const sendContactUsDataForm = createAsyncThunk(
	'contactUs/sendContactUsDataForm',
	sendContactUsDataFormThunk,
);

const contactUsSlice = createSlice({
	name: 'contactUsSlice',
	initialState,
	reducers: {
		closeSubmitAppeal: state => {
			state.isOpenSubmitAppeal = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getAvailableReasons.fulfilled, (state, { payload }: any) => {
				if (payload?.data?.attributes?.reasons) {
					state.reasons = payload?.data?.attributes?.reasons;
				}
			})
			.addCase(sendContactUsDataForm.fulfilled, state => {
				state.isOpenSubmitAppeal = true;
			});
	},
});
export const { closeSubmitAppeal } = contactUsSlice.actions;
export default contactUsSlice.reducer;
