import {
	InputLabel as InputLabelMui,
	InputLabelProps as InputLabelPropsMui,
} from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

type InputLabelProps = InputLabelPropsMui;

const StyledInputLabel = styled(InputLabelMui)({});

const InputLabel: React.FC<InputLabelProps> = props => {
	return (
		<StyledInputLabel {...props} >
			{props.children}
		</StyledInputLabel>
	);
};

export default InputLabel;
