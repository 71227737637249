import { Button, Checkbox, Stack, Typography } from 'styles';
import { FC, useState } from 'react';
import useDialogConfirm, { SUBMIT_LATER } from 'hooks/DialogConfirm.hook';

import { AppDispatch } from 'store/store';
import Documentation from './components/Documentation';
import { IClaim } from 'types/Claim';
import { PageProps } from './NewClaim.component';
import PriceEstimate from './components/PriceEstimate';
import { submitClaim } from 'feature/claims/claimSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DownloadAttestation from './components/DownloadAttestation';

const Page2: FC<PageProps> = props => {
	const { t } = useTranslation();
	const { formData, setFormData } = props;
	const dispatch = useDispatch<AppDispatch>();
	const dialogConfirm = useDialogConfirm();

	const [statusCalculation, setStatusCalculation] = useState<
		'beforeCalculation' | 'afterCalculation' | 'onEdit'
	>(
		formData.amount.components.find(el => !el.retailAmount)
			? 'beforeCalculation'
			: 'afterCalculation',
	);

	return (
		<Stack gap="60px">
			<PriceEstimate
				formData={formData}
				setFormData={setFormData}
				status={statusCalculation}
				setStatus={setStatusCalculation}
			/>
			{['afterCalculation', 'onEdit'].includes(statusCalculation) ? (
				<Stack gap="40px">
					<Stack gap="20px">
						<Typography variant="title6">
							{t('newClaim.selectedPage.sectionTitile.uploadDoc')}
						</Typography>
						<DownloadAttestation />
						<Documentation formData={formData} />
					</Stack>

					<Stack gap="20px">
						<Typography variant="caption" style={{ whiteSpace: 'pre-line' }}>
							{t('newClaim.upload.warning')}
						</Typography>
						<Checkbox
							sx={{ left: '-9px' }}
							label={t('newClaim.upload.uploadConsent')}
							checked={formData.uploadConsent || false}
							onChange={(_, value) => {
								setFormData((prev: IClaim) => ({
									...prev,
									uploadConsent: value,
								}));
							}}
						/>
					</Stack>

					<Stack alignItems="start" gap="20px" direction={'row'}>
						<Button
							variant="outlined"
							onClick={() => {
								dialogConfirm.open(SUBMIT_LATER);
							}}
						>
							{t('newClaim.saveFinishLater')}
						</Button>
						<Button
							disabled={
								!formData.uploadConsent ||
								['beforeCalculation', 'onEdit'].includes(statusCalculation) ||
								!formData.prescription?.id ||
								!formData.receipt?.id ||
								(formData.rxChangeAttestation === 1 && !formData.rxAttestation?.id)
							}
							variant="contained"
							onClick={() => {
								dispatch(submitClaim(formData));
							}}
						>
							{t('newClaim.submitClaim')}
						</Button>
					</Stack>
				</Stack>
			) : null}
		</Stack>
	);
};

export default Page2;
