import {
	DialogTitle as DialogTitleMui,
	DialogTitleProps as DialogTitlePropsMui,
} from '@mui/material';
import Typography from 'styles/typography/Typograpy';
import styled from 'styled-components';

type DialogTitleProps = DialogTitlePropsMui

const StyledDialogTitle = styled(DialogTitleMui)<DialogTitleProps>``;

const DialogTitle: React.FC<DialogTitleProps> = props => {
   
	return (
		<StyledDialogTitle {...props} component={'div'}>
			<Typography variant="title5">{props.title}</Typography>
            {props.children}
		</StyledDialogTitle>
	);
};

export default DialogTitle;
