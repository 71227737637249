import {
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Radio,
	Select,
} from 'styles';

import { SelectChangeEvent } from '@mui/material';
import { SelectProps } from 'styles/select/Select';
import { numericValueToTimeString } from 'utils/utils';
import styled from 'styled-components';

type Props = SelectProps;
const StyledSelect = styled(Select)<Props>``;

function generateHoursArray(): { label: string; value: number }[] {
	const hours: { label: string; value: number }[] = [];
	for (let hour = 0; hour < 24; hour++) {
		for (let minute = 0; minute < 60; minute += 15) {
			const formattedHour = `${hour.toString().padStart(2, '0')}:${minute
				.toString()
				.padStart(2, '0')}`;
			let value = hour + minute / 60;
			hours.push({ label: formattedHour, value });
		}
	}

	return hours;
}

const HOURS = generateHoursArray();

interface SelectedHoursProp {
	label: string;
	selectedHour: number | undefined;
	handleSelectedHours: (event: SelectChangeEvent<any | unknown>) => void;
	checkFields: boolean;
}

const SelectHours: React.FC<SelectedHoursProp> = ({
	label,
	handleSelectedHours,
	selectedHour,
	checkFields,
}) => {
	return (
		<FormControl sx={{ width: { sm: '100%', lg: '235px' } }}>
			<InputLabel>{label}</InputLabel>
			<StyledSelect
				multiple={false}
				variant="filled"
				name={label}
				value={selectedHour ?? ''}
				native={false}
				displayEmpty={true}
				renderValue={e => {
					return numericValueToTimeString(selectedHour);
				}}
				MenuProps={{ PaperProps: { sx: { maxHeight: '268px' } } }}
				onChange={handleSelectedHours}
				error={checkFields && typeof selectedHour !== 'number'}
				disableUnderline
			>
				{HOURS.map((hour: { label: string; value: number }) => {
					return (
						<MenuItem key={hour.value} value={hour.value} disableTouchRipple>
							<Radio checked={selectedHour === hour.value} />
							<ListItemText primary={hour.label} />
						</MenuItem>
					);
				})}
			</StyledSelect>
		</FormControl>
	);
};

export default SelectHours;
