import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IState } from 'types/Document';
import { getDocumentsThunk } from './documentsThunk';

const initialState: IState = {
	errorCode: 0,
	errorMessage: '',
	documents: [],
	expandView: true,
};

export const getDocuments = createAsyncThunk(
	'documentation/getDocuments',
	getDocumentsThunk,
);

const documentsSlice = createSlice({
	name: 'documentation',
	initialState,
	reducers: {
		toggleExpandView: state => {
			state.expandView = !state.expandView;
		},
		resetDocuments: state => {
			state.errorCode = 0;
			state.errorMessage = '';
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getDocuments.fulfilled, (state, { payload }) => {
				state.errorCode = 0;
				state.errorMessage = '';
				state.documents = payload;
			})
			.addCase(getDocuments.rejected, (state, { payload }) => {});
	},
});

export const { resetDocuments, toggleExpandView } = documentsSlice.actions;
export default documentsSlice.reducer;
