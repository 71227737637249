import {
	FormControl,
	FormControlLabel,
	Stack,
	Radio as StylesRadio,
	RadioGroup as StylesRadioGroup,
	Typography,
} from 'styles';

import { RadioGroupProps } from '@mui/material';
import palette from 'styles/theme/Palette';
import styled from 'styled-components';

const Radio = styled(StylesRadio)``;
const RadioGroup = styled(StylesRadioGroup)``;

type StackRadioProps = RadioGroupProps & {
	label: string;
	options: { value: number | string; label: string }[];
	variant?: 'whiteBackground' | 'transparent';
};

const StackRadio = (props: StackRadioProps) => {
	const { label, options, variant = 'whiteBackground', ...radioProps } = props;

	return (
		<Stack sx={{ width: '100%' }}>
			<Typography
				variant={variant === 'whiteBackground' ? 'bodyLink' : 'body'}
				sx={{ mb: 1.25 }}
			>
				{label}
			</Typography>
			{variant === 'whiteBackground' ? (
				<Stack
					direction={'row'}
					alignItems={'center'}
					sx={{
						height: 56,
						background: palette.white.main,
						px: 2,
						width: { sm: '100%', md: '60%' },
					}}
				>
					<FormControl>
						<RadioGroup {...radioProps} sx={{ gap: 5 }}>
							{options.map((option, index) => (
								<FormControlLabel
									key={option.value}
									slotProps={{
										typography: { variant: 'value2', sx: { mr: -1 } },
									}}
									sx={{ mr: 0 }}
									value={option.value}
									control={<Radio sx={{ mr: 1 }} />}
									label={option.label}
								/>
							))}
						</RadioGroup>
					</FormControl>
				</Stack>
			) : (
				<FormControl>
					<RadioGroup {...radioProps} sx={{ gap: 5, mt: 2.5 }}>
						{options.map((option, index) => (
							<FormControlLabel
								key={option.value}
								slotProps={{
									typography: { variant: 'value2', sx: { mr: -1 } },
								}}
								sx={{ mr: 0 }}
								value={option.value}
								control={<Radio sx={{ mr: 1 }} />}
								label={option.label}
							/>
						))}
					</RadioGroup>
				</FormControl>
			)}
		</Stack>
	);
};

export default StackRadio;
