import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { getLocaleLanguage } from 'utils/utils';

export const getLocaleErrorTextsThunk = async (_: any, thunkAPI: any) => {
	const lang = getLocaleLanguage();

	try {
		const response = await authFetch.get(
			`/cms/api/internationalization?locale=${lang}`,
		);

		const errors = response.data?.data?.attributes?.errors;
		if (!errors) throw new Error('No errors');
		return errors;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getLocaleTranslationsThunk = async (_: any, thunkAPI: any) => {
	const lang = getLocaleLanguage();

	try {
		const response = await authFetch.get(
			`/locales/latest/providerportal/${lang}`,
		);

		const res = response.data;
		if (!res) throw new Error('No texts');
		return res;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
