import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
	getLocaleErrorTextsThunk,
	getLocaleTranslationsThunk,
} from './textsLocalesThunk';

interface IState {
	stringMessages?: object;
	localeTranslations: object;
}

const initialState: IState = {
	stringMessages: undefined,
	localeTranslations: {},
};

export const getLocaleErrorTexts = createAsyncThunk(
	'textsLocales/getLocaleErrorTexts',
	getLocaleErrorTextsThunk,
);

export const getLocaleTranslations = createAsyncThunk(
	'textsLocales/getLocaleTranslations',
	getLocaleTranslationsThunk,
);

const textsLocalesSlice = createSlice({
	name: 'textsLocales',
	initialState,
	reducers: {
		resetTextsLocales: () => initialState,
	},
	extraReducers: builder => {
		builder.addCase(getLocaleErrorTexts.fulfilled, (state, { payload }) => {
			return { ...state, stringMessages: payload };
		});
		builder.addCase(getLocaleTranslations.fulfilled, (state, { payload }) => {
			return { ...state, localeTranslations: payload };
		});
	},
});

export const { resetTextsLocales } = textsLocalesSlice.actions;
export default textsLocalesSlice.reducer;
