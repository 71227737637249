import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { saveLocationThunk } from './locationThunk';

interface IState {
	errorCode: number;
	errorMessage: string;
}

const initialState: IState = {
	errorCode: 0,
	errorMessage: '',
};

export const saveLocation = createAsyncThunk(
	'user/saveLocation',
	saveLocationThunk,
);

const locationSlice = createSlice({
	name: 'locations',
	initialState,
	reducers: {
		resetLocations: state => {
			state.errorCode = 0;
			state.errorMessage = '';
		},
	},
	extraReducers: builder => {
		builder.addCase(saveLocation.fulfilled, (state, { payload }) => {
			locationSlice.caseReducers.resetLocations(state);
		});
	},
});

export default locationSlice.reducer;
