import { Input as InputMui, InputProps as InputPropsMui } from '@mui/material';

import styled from 'styled-components';

type InputProps = InputPropsMui;

const StyledInput = styled(InputMui)({});

const Input: React.FC<InputProps> = props => {
	return <StyledInput {...props}></StyledInput>;
};

export default Input;
