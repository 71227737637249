import PaymentDetails from 'components/PaymentDetails/PaymentDetails/PaymentDetails.component';
import {
	getPaymentAssessmentFee,
	getPaymentDetails,
} from 'feature/payments/paymentSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { PaymentDetailsType, PaymentPathnames } from 'types/Payment';

const ViewPaymentDetails = () => {
	const dispatch = useDispatch<any>();
	const location = useLocation();
	const pathnames = location.pathname.split('/');
	const param = useParams<any>();
	let isAssessmentFee = true;

	if (
		pathnames[pathnames.length - 1] === PaymentPathnames.EXPLANATION_OF_PAYMENT ||
		pathnames[pathnames.length - 1] === PaymentPathnames.GROUP_INVOICE_ASSESSMENT
	) {
		isAssessmentFee = false;
	}

	const paymentId = isAssessmentFee
		? pathnames[pathnames.length - 3]
		: pathnames[pathnames.length - 2];

	const detailType = isAssessmentFee
		? pathnames[pathnames.length - 2]
		: pathnames[pathnames.length - 1];

	const claimId = Number(pathnames[pathnames.length - 1]);

	let detailTypeRedux: PaymentDetailsType;

	switch (detailType) {
		case PaymentPathnames.ASSESSMENT_FEE_INVOICE:
			detailTypeRedux = PaymentDetailsType.ASSESSMENT_FEE;
			break;
		case PaymentPathnames.EXPLANATION_OF_PAYMENT:
			detailTypeRedux = PaymentDetailsType.EOP;
			break;
		case PaymentPathnames.GROUP_INVOICE_ASSESSMENT:
			detailTypeRedux = PaymentDetailsType.GROUP_INVOICE;
			break;
		default:
			detailTypeRedux = PaymentDetailsType.EOP;
			break;
	}

	useEffect(() => {
		if (!paymentId || !detailType) {
			return;
		}

		if (isAssessmentFee) {
			dispatch(
				getPaymentAssessmentFee({
					showloader: true,
					claimId: claimId,
				}),
			);
			return;
		}

		dispatch(
			getPaymentDetails({
				showloader: true,
				paymentRunId: param.id as string,
				detailType: detailTypeRedux,
			}),
		);
	}, [
		dispatch,
		paymentId,
		detailType,
		claimId,
		isAssessmentFee,
		detailTypeRedux,
		param.id,
	]);

	return <PaymentDetails />;
};

export default ViewPaymentDetails;
