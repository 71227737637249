import { FC, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import InputSearch from 'components/commons/Dialogs/location/InputSearch';
import { saveLocation } from 'feature/location/locationSlice';
import { resetSearch } from 'feature/search/searchSlice';
import { setActiveButton } from 'feature/user/userSlice';
import useLocationDialog from 'hooks/DialogLocation.hook';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store/store';
import { Box, Typography } from 'styles';
import Button from 'styles/button/Button';
import IconButton from 'styles/button/IconButton';
import Dialog from 'styles/dialog/Dialog';
import LocationList from './locationsList';

const DialogLocation: FC = () => {
	const dispatch = useDispatch<any>();
	const locationDialog = useLocationDialog();
	const {
		open,
		title,
		buttonCancel,
		labelButtonCancel,
		labelButtonSave,
		buttonClose,
	} = useSelector((store: RootState) => store.hooks.location);
	const { type } = useSelector((store: RootState) => store.hooks.dialog);
	const { activeButton } = useSelector((store: RootState) => store.user);
	const { t } = useTranslation();

	const handleSaveLocation = () => {
		dispatch(saveLocation(''));
		dispatch(resetSearch());
		locationDialog.close();
	};

	useEffect(() => {
		dispatch(setActiveButton(false));
	}, [dispatch]);

	return (
		<Dialog
			type={type}
			open={open}
			variant="dialog"
			title={title}
			events={true}
			close={
				buttonClose ? (
					<IconButton disableRipple onClick={() => locationDialog.close()}>
						<CloseDialog />
					</IconButton>
				) : null
			}
			actions={
				<Fragment>
					{!!buttonCancel && (
						<Button variant="outlined" onClick={() => locationDialog.close()}>
							{labelButtonCancel}
						</Button>
					)}
					<Button
						variant="contained"
						disabled={!activeButton}
						onClick={handleSaveLocation}
					>
						{labelButtonSave}
					</Button>
				</Fragment>
			}
		>
			<Box mb={2.5}>
				<Typography variant="body">
					{t('dialog.changeLocation.subtitle')}
				</Typography>
			</Box>
			<InputSearch />
			<LocationList />
		</Dialog>
	);
};

export default DialogLocation;
