import { getClaimDetails, resetClaim } from "feature/claims/claimSlice";

import ClaimDetails from "components/ViewClaimDetails/ClaimDetails.component";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const ViewClaimDetails = () => {
	const param = useParams<any>();
	const dispatch = useDispatch<any>();

	useEffect(() => {
		dispatch(resetClaim());
		dispatch(getClaimDetails(param.id as string))
	}, [dispatch, param.id])

	return <ClaimDetails />
};

export default ViewClaimDetails;
