import { Table as TableMui, TableProps as TablePropsMui } from '@mui/material';

import styled from 'styled-components';

type TableProps = TablePropsMui;

const StyledTable = styled(TableMui)({});

const Table: React.FC<TableProps> = props => {
	return <StyledTable {...props}>{props.children}</StyledTable>;
};

export default Table;
