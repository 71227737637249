import Payment from 'components/PaymentDetails/Payment.component';
import { getPaymentRun, resetPayment } from 'feature/payments/paymentSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const ViewPayment = () => {
	const param = useParams<any>();
	const dispatch = useDispatch<any>();

	useEffect(() => {
		dispatch(resetPayment());
		dispatch(getPaymentRun({ showloader: true, id: param.id as string }));
	}, [param.id, dispatch]);

	return <Payment />;
};

export default ViewPayment;
