import { Pagination as PaginationMui, PaginationProps as PaginationPropsMui } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

type PaginationProps = PaginationPropsMui;

const StyledPagination = styled(PaginationMui)({});

const Pagination: React.FC<PaginationProps> = props => {
	return <StyledPagination {...props} />;
};

export default Pagination;
