import { Divider as DividerMui, DividerProps as DividerPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type DividerProps = DividerPropsMui

const StyledDivider = styled(DividerMui)({})

const Divider:React.FC<DividerProps> = props => {
    return <StyledDivider  {...props}>
        {props.children}
    </StyledDivider >
}

export default Divider;