import { Box, Button, Dialog, IconButton, Typography } from 'styles';
import { FC, useEffect, useState } from 'react';
import {
	deleteDocument,
	editDocumentName,
	setDocumentTypeSelected,
} from 'feature/upload/uploadSlice';

import { AppDispatch } from 'store/store';
import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import { DocType } from 'types/Upload';
import { IFileType } from 'types/Claim';
import UploadedFileBox from './UploadFileBox';
import { camelcaseToUnderscore } from 'utils/utils';
import { setDialogOpen } from '../../feature/hooks/uploadFileDialogSlice';
import { useDispatch } from 'react-redux';
import { useFileOpener } from 'hooks/downloader.hook';
import { useTranslation } from 'react-i18next';

const disallowedCharsRegex = /^[^\\/.*?]*$/;

type DocumentationClaimProps = {
	label: string;
	labelUpload: string;
	documentType: DocType;
	data?: IFileType;
};

const UploadFileTile: FC<DocumentationClaimProps> = ({
	label,
	labelUpload,
	documentType,
	data,
}) => {
	const [fileNameField, setFileNameField] = useState('');
	const [dialogFlag, setDialogFlag] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	const documentId = data?.id;
	const fileName = data?.fileName;
	const [isEditMode, setIsEditMode] = useState(false);

	const onOpenModal = () => {
		dispatch(setDialogOpen(true));
		dispatch(setDocumentTypeSelected(documentType));
	};

	const onDelete = () => {
		if (documentId) {
			dispatch(deleteDocument({ id: documentId, docType: documentType }));
			setDialogFlag(false);
		} else {
			// console.log('no docId found');
		}
	};

	const handlerFileNameAction = () => {
		setIsEditMode(false);
		if (fileNameField && fileNameField.trim() !== fileName) {
			dispatch(
				editDocumentName({
					docType: documentType,
					documentId: documentId!,
					fileName: fileNameField.trim(),
				}),
			);
		}
	};

	const handleTextInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
		if (disallowedCharsRegex.test(evt.target.value)) {
			setFileNameField(evt.target.value);
		}
	};

	const fileOpener = useFileOpener();
	const handlerDownloadFile = () => {
		const url = `${process.env.REACT_APP_API_URL
			}/attachments/${camelcaseToUnderscore(documentType)}/${documentId}/content`;

		fileOpener(url, fileName!);
	};

	useEffect(() => setFileNameField(fileName?.split('.')[0] || ''), [fileName]);

	return (
		<>
			{!data?.id ? (
				<Button variant="upload" onClick={onOpenModal}>
					<Typography
						sx={{ textDecoration: 'underline', textUnderlineOffset: '5px' }}
						variant="button"
					>
						{labelUpload}
					</Typography>
				</Button>
			) : (
				<UploadedFileBox
					isEditMode={isEditMode}
					enableEditModeAction={() => setIsEditMode(true)}
					enableDialogAction={() => setDialogFlag(true)}
					handlerFileName={handleTextInput}
					handlerFileNameAction={handlerFileNameAction}
					fileName={fileNameField}
					label={label}
					canDownloadFile
					handlerDownloadFile={handlerDownloadFile}
				/>
			)}
			<Dialog
				title={t(`dialog.confirm.deleteUploadedFile.${documentType}`)}
				close={
					<IconButton onClick={() => setDialogFlag(false)}>
						<CloseDialog />
					</IconButton>
				}
				open={dialogFlag}
				variant="confirm"
				events
				actions={
					<>
						<Button variant="outlined" onClick={() => setDialogFlag(false)}>
							{t(`dialog.buttons.cancel`)}
						</Button>
						<Button onClick={onDelete} variant="contained">
							{t(`dialog.buttons.continue`)}
						</Button>
					</>
				}
				type=""
			>
				<Box display="flex" gap="1rem" flexDirection="column">
					<Typography variant="body">
						{t('dialog.confirm.deleteUploadedFile.content', {
							docName: fileName,
						})}
					</Typography>
				</Box>
			</Dialog>
		</>
	);
};

export default UploadFileTile;
