import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';

import ClaimStatusBreadcrumbs from './ClaimStatusBreadcrumbs.component';
import Paper from 'styles/paper/paper';
import { RootState } from 'store/store';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useSelector } from 'react-redux';

const ClaimMemberDetails = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));
	const { claimData: claim } = useSelector((store: RootState) => store.claim);

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2.5,
				justifyContent: 'space-between',
				display: 'flex',
				borderRadius: 0.5,
				width: !!desktop ? '1344px' : '100%',
				maxWidth: '1344px',
			}}
		>
			<Stack flexGrow={1} rowGap={1.875}>
				<Typography variant="body">
					<Trans
						i18nKey={t('claim.labels.memberFullName')}
						values={{ memberFullName: claim?.memberFullName }}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={!!desktop ? 'bodyLink' : 'bodyLink'}
								/>
							),
						}}
					/>
				</Typography>
				<Typography variant="body">
					<Trans
						i18nKey={t('claim.labels.memberId')}
						values={{ memberId: claim?.memberId }}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={!!desktop ? 'bodyLink' : 'bodyLink'}
								/>
							),
						}}
					/>
				</Typography>
				<Typography variant="body">
					<Trans
						i18nKey={t('claim.labels.memberDesignation')}
						values={{ memberDesignation: claim?.memberDesignation }}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={!!desktop ? 'bodyLink' : 'bodyLink'}
								/>
							),
						}}
					/>
				</Typography>
			</Stack>
			<Stack flexGrow={0} width={'auto'} rowGap={1.875}>
				<Typography variant="body">
					<Trans
						i18nKey={t('claim.labels.planName')}
						values={{ planName: claim?.planName }}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={!!desktop ? 'bodyLink' : 'bodyLink'}
								/>
							),
						}}
					/>
				</Typography>
				<Typography variant="body">
					<Trans
						i18nKey={t('claim.labels.memberProviderLocation')}
						values={{ memberProviderLocation: claim?.memberProviderLocation }}
						components={{
							bold: (
								<Typography
									sx={{ ml: 1 }}
									variant={!!desktop ? 'bodyLink' : 'bodyLink'}
								/>
							),
						}}
					/>
				</Typography>
				<Typography
					variant="body"
					sx={{ display: 'flex', height: '20px', alignItems: 'center', gap: 1 }}
				>
					<Trans
						i18nKey={t('claim.labels.status')}
						values={{ claimStatus: claim?.claimStatus }}
						components={{
							bold: <ClaimStatusBreadcrumbs claim={claim} />,
						}}
					/>
				</Typography>
			</Stack>
		</Paper>
	);
};

export default ClaimMemberDetails;
