import {
	Container as ContainerMui,
	ContainerProps as ContainerPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type ContainerProps = ContainerPropsMui;

const StyledContainer = styled(ContainerMui)``;

const Container: React.FC<ContainerProps> = props => {
	return <StyledContainer {...props}>{props.children}</StyledContainer>;
};

export default Container;
