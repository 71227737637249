import { FormControlLabel as FormControlLabelMui, FormControlLabelProps as FormControlLabelPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type FormControlLabelProps = FormControlLabelPropsMui

const StyledFormControlLabel = styled(FormControlLabelMui)({})

const FormControlLabel:React.FC<FormControlLabelProps> = props => {
    return <StyledFormControlLabel  {...props}  />
}

export default FormControlLabel;