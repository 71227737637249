import { FormControl as FormControlMui, FormControlProps as FormControlPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type FormControlProps = FormControlPropsMui

const StyledFormControl = styled(FormControlMui)({})

const FormControl:React.FC<FormControlProps> = props => {
    return <StyledFormControl  {...props}>
        {props.children}
    </StyledFormControl >
}

export default FormControl;