import { Trans, useTranslation } from 'react-i18next';
import useDialogLocation, { CHANGE_LOCATION } from 'hooks/DialogLocation.hook';

import Button from 'styles/button/Button';
import Paper from 'styles/paper/paper';
import { RootState } from 'store/store';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useSelector } from 'react-redux';

const UserProvider: React.FC = () => {
	const { t } = useTranslation();
	const { profile } = useSelector((store: RootState) => store.user);
	const dialogLocation = useDialogLocation();

	const handleChangeLocation = () => {
		dialogLocation.open(CHANGE_LOCATION);
	};

	return (
		<Paper variant="light">
			<Stack flexGrow={1} direction={'row'} gap={1.8}>
				<Typography variant="value3">
					<Trans
						i18nKey={'dashboard.providerPanel.providerId'}
						values={{ providerId: profile?.providerId ? profile?.providerId : '-' }}
						components={{ bold: <Typography variant="value1" /> }}
					/>
				</Typography>
				<Typography variant="value3">
					<Trans
						i18nKey={'dashboard.providerPanel.location'}
						values={{ location: profile?.location }}
						components={{ bold: <Typography variant="value1" /> }}
					/>
				</Typography>
			</Stack>
			<Stack flexGrow={0}>
				<Button variant="linkDark" onClick={() => handleChangeLocation()}>
					{t('dashboard.providerPanel.changeLocation')}
				</Button>
			</Stack>
		</Paper>
	);
};

export default UserProvider;
