import { useEffect, useState } from 'react';

export function useDebounce(value: string, delay = 2000) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => clearTimeout(timeout);
	}, [delay, value]);

	return debouncedValue;
}
