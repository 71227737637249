import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFooterLinksThunk } from './footerThunk';
import { IFooterLinks } from 'types/FooterLinks';

const initialState: IFooterLinks = {
  errorCode: 0,
  errorMessage: '',
  links: [],
};

export const getFooterLinks = createAsyncThunk(
  'footer/getFooterLinks',
  getFooterLinksThunk,
);

const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getFooterLinks.fulfilled, (state, { payload }) => {
      state.errorCode = 0;
      state.errorMessage = '';
      state.links = payload;
    });
  },
});

// export const { } = footerSlice.actions;
export default footerSlice.reducer;