import { Button, ButtonProps, Typography } from '@mui/material';

type Props = ButtonProps & {
	label: string;
	to: string;
};

const ButtonLink: React.FC<Props> = props => {
	return (
		<Button
			startIcon={props.startIcon}
			disableElevation
			disableTouchRipple
			disableRipple
			disableFocusRipple
			variant="linkDark"
			type="button"
			href={props.to}
			target="_blank"
		>
			<Typography variant="button">{props.label}</Typography>
		</Button>
	);
};

export default ButtonLink;
