import {
	StepLabel as StepLabelMui,
	StepLabelProps as StepLabelPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type StepLabelProps = StepLabelPropsMui;

const StyledStepLabel = styled(StepLabelMui)``;

const StepLabel: React.FC<StepLabelProps> = props => {
	return <StyledStepLabel {...props} />;
};

export default StepLabel;
