import { Box, Stack, Typography } from 'styles';
import palette from 'styles/theme/Palette';

interface PrintConfigurationProps {
	label: string;
	data: string[];
	width?: string;
}

const PrintConfiguration: React.FC<PrintConfigurationProps> = ({
	label,
	data,
	width,
}) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={{ sm: '0.625rem' }}
			width={width || '100%'}
		>
			<Typography pl={2} variant="bodyLink">
				{label}
			</Typography>
			<Stack
				borderRadius={1}
				height={{ sm: '4rem', lg: '4.375rem' }}
				direction="row"
				alignItems="center"
				sx={{ backgroundColor: palette.white.main }}
				px={2}
				flexWrap="wrap"
				p="0.5rem 1rem"
			>
				<Typography variant="value2">{data.join(', ')}</Typography>
			</Stack>
		</Box>
	);
};

export default PrintConfiguration;
