import { Dayjs } from 'dayjs';

export interface IFilterState {
	collection: Collections;
	size: number;
	search: boolean;
	searchCheckElegibility: string;
	buttonSearchCheckElegibility: boolean;
	inputFiscalCode: string;
	inputMemberId: string;
	inputFirstName: string;
	inputLastName: string;
	inputDateOfBirth: Dayjs | null;
	dateOfService: Dayjs | null;
	filterDateOfService: Dayjs | null;
	inputClaimId: string;
	inputExternalClaimId: string;
	inputClaimFirstName: string;
	inputClaimLastName: string;
	inputClaimDate: string;
	inputClaimStatus: string;
	inputPaymentRunId: string | null;
	inputTotalAmount: number | null;
	sortingFields: string;
	sortingDirections: sortingDirections;
}

export interface IState {
	errorCode: number;
	errorMessage: string;
}

export interface IErrorInputResponse {
	errorCode: number;
	errorMessage: string;
}

export interface IFilterCheckEligibility
	extends IFilterState,
		IErrorInputResponse {}

export enum OptionsDatepicker {
	margin = 'margin',
	field = 'field',
}

export enum sortingDirections {
	DESC = 'DESC',
	ASC = 'ASC',
}

export enum Collections {
	NONE = 'none',
	CLAIMS = 'claims',
	PAYMENTS = 'payments',
}

export const defaultSortingField = {
	[Collections.NONE]: '',
	[Collections.CLAIMS]: 'serviceDate,externalClaimId',
	[Collections.PAYMENTS]: 'paymentRunDate',
};
