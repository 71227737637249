import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography } from 'styles';

import { ReactComponent as ChevronDownIcon } from 'assett/icons/arrow_down_icon.svg';
import { ReactComponent as ChevronUpIcon } from 'assett/icons/arrow_up_icon.svg';
import { handleChange, setSortingValues } from 'feature/form/formSlice';
import { changePage } from 'feature/payments/paymentSlice';
import { useDebounce } from 'hooks/debouncer.hook';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store/store';
import TextField from 'styles/textfield/TextFieldLogin';
import { sortingDirections } from 'types/Form';
import { camelcase } from 'utils/utils';

interface Props {
	name: string;
	children?: React.ReactNode;
	widthBox: number | string;
	widthInput?: number;
	noMargins?: boolean;
	primarySorting?: string;
}

const HeaderCellWithSorting: FC<Props> = props => {
	const { t } = useTranslation();
	const dispatch = useDispatch<any>();
	const inputForm = useSelector((store: RootState) => store.form);
	const currentInputName = `input${camelcase(props.name)}`;

	const handleSorting = (name: string, value: string) => {
		dispatch(changePage(1)); // set the

		if (props.primarySorting) {
			dispatch(
				setSortingValues({
					name: name + `,${props.primarySorting}`,
					value: value + ',DESC',
				}),
			);
		} else {
			dispatch(setSortingValues({ name, value }));
		}
	};

	const [filterValue, setFilterValue] = useState<string | undefined>(undefined);
	const filterValueDeb = useDebounce(filterValue || '');

	useEffect(() => {
		if (!props.children)
			dispatch(
				handleChange<any>({
					name: currentInputName,
					value: filterValueDeb,
				}),
			);
	}, [currentInputName, dispatch, filterValueDeb, props.children]);

	return (
		<Box sx={{ width: props.widthBox }}>
			{inputForm.search ? (
				!props.children ? (
					<TextField
						label={t(`forms.header.${props.name}`)}
						sx={{ width: props.widthInput }}
						name={currentInputName}
						onChange={evt => setFilterValue(evt.target.value)}
						value={filterValue || ''}
					/>
				) : (
					props.children
				)
			) : (
				<Stack direction={'row'} alignItems={'center'}>
					<Typography
						variant="bodyLink"
						sx={{ ml: props.noMargins ? 0 : 1.7, cursor: 'pointer' }}
						onClick={() =>
							handleSorting(
								props.name,
								inputForm.sortingFields.split(',')[0] === props.name &&
									inputForm.sortingDirections.split(',')[0] === sortingDirections.ASC
									? sortingDirections.DESC
									: sortingDirections.ASC,
							)
						}
					>
						{t(`forms.header.${props.name}`)}
					</Typography>

					{inputForm.sortingFields.split(',')[0] === props.name ? (
						inputForm.sortingDirections.split(',')[0] === sortingDirections.ASC ? (
							<ChevronUpIcon />
						) : (
							<ChevronDownIcon />
						)
					) : null}
				</Stack>
			)}
		</Box>
	);
};

export default HeaderCellWithSorting;
