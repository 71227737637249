import { Step as StepMui, StepProps as StepPropsMui } from '@mui/material';

import styled from 'styled-components';

type StepProps = StepPropsMui;

const StyledStep = styled(StepMui)``;

const Step: React.FC<StepProps> = props => {
	return <StyledStep {...props} />;
};

export default Step;
