import {
	TextField as TextFieldMui,
	TextFieldProps as TextFieldPropsMui,
} from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

type TextFieldProps = TextFieldPropsMui;

const StyledTextField = styled(TextFieldMui)({});

const TextField: React.FC<TextFieldProps> = props => {
	return (
		<StyledTextField
			InputProps={{
				disableUnderline: true,
			}}
			variant="filled"
			{...props}
		>
			{props.children}
		</StyledTextField>
	);
};

export default TextField;
