import { createClaimsSearchValues, formatDayjsForBE } from 'utils/utils';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { thunkLoadingParams } from 'feature/progressIndicator/progressIndicatorSlice';
import { IClaimDownload } from 'types/Claims';
import { exportListToExcel } from 'utils/download';

export const getClaimsByProviderThunk = async (
	arg: thunkLoadingParams & { limit?: string },
	thunkAPI: any,
) => {
	let getClaimsURL = `/claim`;

	const { page } = thunkAPI.getState().claims;
	const claimsSearchValues = thunkAPI.getState().form;
	const {
		profile: { providerId },
	} = thunkAPI.getState().user;

	const { sortingFields, sortingDirections } = thunkAPI.getState().form;

	const limitParam = arg?.limit ? `&limit=${arg.limit}` : '';
	const searchParams = createClaimsSearchValues(claimsSearchValues);
	if (providerId) {
		getClaimsURL =
			getClaimsURL +
			`?page=${page}${limitParam}&providerId=${providerId}&sortingFields=${sortingFields}&sortingDirections=${sortingDirections}${searchParams ? searchParams : ''
			}`;
		try {
			const response = await authFetch.get(getClaimsURL);
			return response.data;
		} catch (error: any) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};

export const getClaimsStatusThunk = async (_: any, thunkAPI: any) => {
	let getClaimsStatusURL = '/claim/status';

	try {
		const response = await authFetch.get(getClaimsStatusURL);
		return response.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const downloadClaimsThunk = async (
	{ id, type }: IClaimDownload,
	thunkAPI: any,
) => {
	let getDownloadClaimIdURL = '/claim/downloadClaimDetail';
	let getDownloadClaimsListURL = `/claim/downloadClaimList`;

	if (id !== 0) {
		getDownloadClaimIdURL = getDownloadClaimIdURL + `?claimId=${id}`;
		try {
			const response = await authFetch.get(getDownloadClaimIdURL, {
				responseType: 'blob',
			});
			exportListToExcel(response.data, 'Claim', type);
			return true;
		} catch (error: any) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	} else {
		const {
			claimIds,
			claimExcludeIds,
			downloadAll,
		} = thunkAPI.getState().claims;
		const { inputExternalClaimId, inputFirstName, inputLastName, dateOfService, inputClaimStatus, sortingFields, sortingDirections, } = thunkAPI.getState().form;

		const {
			profile: { providerId },
		} = thunkAPI.getState().user;

		const values = {
			format: type,
			claimIds: downloadAll ? [] : claimIds,
			claimExcludeIds: claimExcludeIds,
			providerId: providerId,
			externalClaimId: inputExternalClaimId,
			firstName: inputFirstName ? inputFirstName : null,
			lastName: inputLastName ? inputLastName : null,
			serviceDate: dateOfService ? formatDayjsForBE(dateOfService) : null,
			claimStatus: inputClaimStatus ? inputClaimStatus : null,
			sortingFields: sortingFields,
			sortingDirections: sortingDirections,
		};
		try {
			const response = await authFetch.post(getDownloadClaimsListURL, values, {
				responseType: 'blob',
			});
			exportListToExcel(response.data, 'Claims', type);
			return true;
		} catch (error: any) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};
