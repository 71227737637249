import { ListItem as ListItemMui, ListItemProps as ListItemPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type ListItemProps = ListItemPropsMui

const StyledListItem = styled(ListItemMui)``

const ListItem:React.FC<ListItemProps> = props => {
    return <StyledListItem {...props}>
        {props.children}
    </StyledListItem>
}

export default ListItem;