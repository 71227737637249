import { Stepper, StepperProps } from '@mui/material';

import Step from './Step';
import StepButton from './StepButton';

export type customStepperProps = { title: string; page: number };

interface Props extends StepperProps {
	steps: customStepperProps[];
	selectedPage?: number;
	setSelectedPage?: (pageNumber: number) => void;
}

const StepperUI: React.FC<Props> = props => {
	const { steps, selectedPage, setSelectedPage, ...stepperProps } = props;

	return (
		<Stepper {...stepperProps} nonLinear alternativeLabel>
			{steps.map(({ title, page }, index) => (
				<Step
					active={page <= (selectedPage ? selectedPage : 0)}
					key={page}
					onClick={() => {
						selectedPage &&
							setSelectedPage &&
							selectedPage > index &&
							setSelectedPage(index);
					}}
				>
					<StepButton disableRipple disableTouchRipple>
						{title}
					</StepButton>
				</Step>
			))}
		</Stepper>
	);
};

export default StepperUI;
