import { Box } from 'styles';
import { DocType } from 'types/Upload';
import { FC } from 'react';
import { IClaim } from 'types/Claim';
import UploadFile from 'components/UploadFile/UploadFileTile';
import { useTranslation } from 'react-i18next';

type prescriptionProps = {
	formData: IClaim;
};

const Documentation: FC<prescriptionProps> = props => {
	const { formData } = props;
	const { t } = useTranslation();

	let docList: DocType[] =
		formData.rxChangeAttestation === 1
			? ['prescription', 'rxAttestation', 'receipt']
			: ['prescription', 'receipt'];

	return (
		<Box display="flex" gap="0.938rem">
			{docList.map((doc, index) => {
				return (
					<UploadFile
						key={index}
						label={t(`newClaim.upload.${doc}`)}
						labelUpload={t(`newClaim.upload.${doc}U`)}
						documentType={doc}
						data={formData[doc]}
					/>
				);
			})}
		</Box>
	);
};

export default Documentation;
