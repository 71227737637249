import useDialogConfirm, { SHOW_LIMITATIONS } from 'hooks/DialogConfirm.hook';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createClaim } from 'feature/claims/claimSlice';
import { resetForm } from 'feature/form/formSlice';
import { getLimitations } from 'feature/limitations/limitationsSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { RootState } from 'store/store';
import { BoxOverflow } from 'styles';
import Box from 'styles/box/Box';
import Button from 'styles/button/Button';
import Stack from 'styles/stack/Stack';
import MemberPlans from './components/MemberBenefits.component';
import MemberDetail from './components/MemberDetail.component';
import MemberDoctorType from './components/MemberDoctorType.component';

const CheckEligiblityMemberDetail: FC = () => {
	const { isDesktop } = useDevice();
	const { member, pending } = useSelector((store: RootState) => store.member);
	const { limitations } = useSelector((store: RootState) => store.limitations);
	const { profile, features } = useSelector((store: RootState) => store.user);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dialog = useDialogConfirm();
	const dispatch = useDispatch<any>();

	const featuresRole = features.map(el => el.code);

	const handleShowLimitations = () => {
		dispatch(getLimitations(''));
		dialog.open(SHOW_LIMITATIONS);
	};
	useEffect(() => {
		if (!pending && !member) {
			navigate('/check-and-submit');
		}
	}, [pending, member, navigate]);

	useEffect(() => {
		dispatch(resetForm());
	}, [dispatch]);

	useEffect(() => {
		if (limitations.length > 0) {
			dialog.open(SHOW_LIMITATIONS);
		}
	}, [dialog, limitations]);

	const BenefitContent = (
		<Box width={'100%'}>
			<Box width={'100%'} sx={{ pb: 2 }}>
				<Stack rowGap={isDesktop ? '60px' : 2.5}>
					<MemberDetail />
					{isDesktop ? (
						<Stack maxHeight={'40vh'}>
							<MemberPlans />
						</Stack>
					) : (
						<MemberPlans />
					)}
				</Stack>
				<Stack
					height={24}
					direction={'row'}
					width={'100%'}
					justifyContent={'space-between'}
					sx={{ mt: 3.125 }}
				>
					<Button variant="underline" onClick={handleShowLimitations}>
						{t('memberDetail.labels.showLimitations')}
					</Button>
					<MemberDoctorType />
				</Stack>
			</Box>
			<Stack>
				{featuresRole.includes('submit-claims') && member?.eligible ? (
					<Button
						sx={{
							width: 'max-content',
							mt: isDesktop ? 3 : 4.5,
							justifyContent: 'center',
						}}
						variant="contained"
						onClick={() => {
							dispatch(
								createClaim({
									memberFullName: member?.memberName,
									beneficiaryMemberId: member?.memberId,
									memberId: member?.memberId,
									memberSubscriptionId: member?.subscriptionId,
									beneficiarySubscriptionId: member?.subscriptionId,
									providerId: profile?.providerId,
									claimDate: member?.dateOfService,
								}),
							);
							navigate(`/check-and-submit/${member?.memberName}/new-claim`);
						}}
					>
						{t('check-and-submit.buttons.newClaim')}
					</Button>
				) : null}
			</Stack>
		</Box>
	);

	return (
		<Stack
			direction="row"
			justifyContent={'space-between'}
			sx={{ height: '98%' }}
		>
			{isDesktop ? (
				<Box width={'100%'}>{BenefitContent}</Box>
			) : (
				<BoxOverflow variant="y" sx={{ paddingRight: '5px', width: '100%' }}>
					{BenefitContent}
				</BoxOverflow>
			)}
		</Stack>
	);
};

export default CheckEligiblityMemberDetail;
