import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IPaymentClaimShort } from 'types/Payment';
import { sortingDirections as sorting } from '../types/Form';

export const useSortedClaim = () => {
	const { paymentRun } = useSelector((store: RootState) => store.payment);
	const { sortingFields, sortingDirections } = useSelector(
		(store: RootState) => store.form,
	);

	const sortPaymentClaimsFunction = useCallback(
		(a: IPaymentClaimShort, b: IPaymentClaimShort) => {
			switch (sortingDirections) {
				case sorting.ASC:
					return (
						(a[sortingFields as keyof IPaymentClaimShort] as number) -
						(b[sortingFields as keyof IPaymentClaimShort] as number)
					);
				case sorting.DESC:
					return (
						(b[sortingFields as keyof IPaymentClaimShort] as number) -
						(a[sortingFields as keyof IPaymentClaimShort] as number)
					);
				default:
					return 0;
			}
		},
		[sortingFields, sortingDirections],
	);

	if (paymentRun.claims.length === 0) return paymentRun.claims;

	return [...paymentRun.claims].sort((a, b) => sortPaymentClaimsFunction(a, b));
};
