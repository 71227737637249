import {
	ThemeProvider as MuiThemeProvider,
	StyledEngineProvider,
} from '@mui/material/styles';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline as MuiCssBaseline } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { Frame } from 'appContainer/Frame';
import { msalConfig } from 'config/msalConfig';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ErrorPage from 'pages/Error.page';
import { FC } from 'react';
import CacheBuster from 'react-cache-buster';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { themeSystem } from 'styles/theme/ThemeSystem';
import TranslationProvider from 'utils/TranslationProvider';
import { injectReduxStore } from 'utils/axios';
import packageInfo from '../package.json';
import { store } from './store/store';
import { theme } from './styles/theme/ThemeStyle';

injectReduxStore(store);

const pca = new PublicClientApplication(msalConfig);

export const App: FC = () => {
	dayjs.extend(utc);
	dayjs.extend(timezone);

	const isProduction = process.env.NODE_ENV === 'production';

	return (
		<CacheBuster
			currentVersion={packageInfo.version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
		>
			<Provider store={store}>
				<MsalProvider instance={pca}>
					<TranslationProvider>
						<StyledEngineProvider injectFirst>
							<MuiThemeProvider theme={deepmerge(themeSystem, theme)}>
								<MuiCssBaseline />
								<StyledComponentsThemeProvider theme={theme}>
									<ErrorBoundary fallback={<ErrorPage />}>
										<Frame />
									</ErrorBoundary>
								</StyledComponentsThemeProvider>
							</MuiThemeProvider>
						</StyledEngineProvider>
					</TranslationProvider>
				</MsalProvider>
			</Provider>
		</CacheBuster>
	);
}; /*  */
