import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IMembersResponseHits, IState } from 'types/Members';
import { isSubscriptionValid } from 'utils/utils';
import { searchMemberThunk } from './membersThunk';

const initialState: IState = {
	errorCode: 0,
	errorMessage: '',
	members: null,
};

export const searchMembers = createAsyncThunk(
	'members/searchMembers',
	searchMemberThunk,
);

const membersSlice = createSlice({
	name: 'members',
	initialState,
	reducers: {
		resetMembers: () => initialState,
	},
	extraReducers: builder => {
		builder.addCase(searchMembers.fulfilled, (state, { payload }) => {
			const { member_id, date_of_service } = payload.inputProps;

			const members = payload.hits.hits
				.map((member: IMembersResponseHits) => {
					const { _id } = member;
					const {
						firstName,
						lastName,
						fullName,
						fiscalCodeMasked,
						subscriptions,
						birthDate,
					} = member._source;

					const subs = subscriptions.filter(sub =>
						isSubscriptionValid(sub, member_id, date_of_service),
					);

					return {
						_id,
						firstName,
						lastName,
						fullName,
						fiscalCodeMasked,
						subscriptions: subs,
						birthDate,
					};
				})
				.filter((member: any) => member.subscriptions.length > 0);

			state.members = {
				...payload.hits,
				hits: members,
				total: {
					...payload.hits.total,
					value: members.length,
				},
			};
		});
		builder.addCase(searchMembers.rejected, (state, { payload }: any) => {
			state.errorCode = payload.errorCode;
			state.errorMessage = payload.message;
			state.members = null;
		});
	},
});

export const { resetMembers } = membersSlice.actions;
export default membersSlice.reducer;
